@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.articleCardWrapper {
  position: relative;
  opacity: 0;
  transition: all 0.8s ease;
  transform: scale(0.8);
  transform-origin: center center;
  @media (max-width: $breakpoint-md) {
    width: auto;
  }
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
  .category_position {
    position: absolute;
    top: 1.111vw;
    left: 1.111vw;
    background: $light_grey;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 6.944vw;
    text-transform: uppercase;
    padding: 0.278vw 1.111vw;
    color: $discharging;
    font-size: 0.833vw;
    line-height: 120%;
    font-family: 'TWKLausanne-700';
    z-index: 2;
    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      border-radius: 100px;
      padding: 4px 16px;
      top: 16px;
      left: 16px;
    }
  }
  .image_wrapper {
    width: 16.667vw;
    height: 16.667vw;
    position: relative;
    background: rgba(14, 11, 53, 0.04);
    border-radius: 0.833vw 0.833vw 0.833vw 0px;
    @media (max-width: $breakpoint-md) {
      width: 255px;
      height: 255px;
    }
    img {
      border-radius: 0.833vw 0.833vw 0.833vw 0px;
      @media (max-width: $breakpoint-md) {
        border-radius: 12px 12px 12px 0px;
      }
    }
    &.customerStoriesImg {
      width: inherit;
      @media (max-width: $breakpoint-md) {
        width: 255px;
      }
    }
    &.newsRoomImg {
      width: inherit;
      @media (max-width: $breakpoint-md) {
        width: auto;
      }
    }
  }
  .article_body_wrapper {
    padding: 1.111vw;
    background-color: $white;
    box-shadow: 0px 3.333vw 3.333vw 0px $box_shadow;
    border-radius: 0.833vw;
    margin: -2.083vw 1.111vw 0 1.111vw;
    position: relative;
    @media (max-width: $breakpoint-md) {
      margin: -32px 16px 0 16px;
      padding: 16px;
      border-radius: 12px;
      box-shadow: 0px 48px 48px 0px $box_shadow;
    }
    .date_wrapper {
      color: $medium_grey;
      font-size: 0.833vw;
      text-transform: uppercase;
      line-height: 120%;
      margin-bottom: 0.278vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
    .description {
      p {
        color: $dark_grey;
        font-size: 0.972vw;
        font-family: 'TWKLausanne-700';
        line-height: 150%;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
        }
      }
    }
    &.customerStoriesWrapper {
      max-width: 20.556vw;
      padding: 1.111vw;
      font-size: 0.972vw;
      line-height: 1.458vw;
      box-shadow: 0px 3.333vw 3.333vw 0px $box_shadow;
      @media (max-width: $breakpoint-md) {
        max-width: 221px;
        padding: 16px;
        font-size: 14px;
        line-height: 21px;
        box-shadow: 0px 48px 48px 0px $box_shadow;
      }
      .cardTitle > p {
        font-family: 'TWKLausanne-700';
        color: $dark_grey;
        margin-bottom: 0.278vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 4px;
        }
      }
      .cardDescription > p {
        font-family: 'TWKLausanne-300';
        color: $medium_grey;
        margin-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 16px;
        }
      }
      .btn {
        display: flex;
        align-items: center;
        font-family: 'TWKLausanne-700';
        font-size: 0.972vw;
        line-height: 0.972vw;
        padding: 1.111vw 0.833vw;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 14px;
          padding: 16px 12px;
        }
        img {
          margin-left: 0.833vw;
          @media (max-width: $breakpoint-md) {
            margin-left: 12px;
          }
        }
      }
    }
  }
  .bottom_link {
    padding: 0.833vw;
    background: $white;
    border-radius: 6.944vw;
    width: 3.333vw;
    height: 3.333vw;
    display: block;
    margin: 1.111vw auto 0 auto;
    @media (max-width: $breakpoint-md) {
      display: none;
    }
    img {
      width: 1.667vw;
      height: 1.667vw;
    }
  }
  &.scrollSnapWrapper {
    z-index: 1;
    @media (max-width: $breakpoint-md) {
      padding-left: 16px;
      scroll-snap-align: start;
    }
    .category_position {
      @media (max-width: $breakpoint-md) {
        left: 32px;
      }
    }
  }
}
