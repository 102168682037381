@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.fourColumn_section {
  position: relative;
  background-color: #ffffff;
  padding: 80px 0 40px 0;
  * {
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
  }
  .fourColumn_wrapper {
    max-width: 70.139vw;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 0.8s ease;
    @media (max-width: $breakpoint-xl) {
      max-width: 100%;
      margin: 0 0 64px 16px;
    }
    &.visible {
      opacity: 1;
      .textContent {
        transform: translateY(0);
      }
    }
    .textContent {
      transform: translateY(20px);
      transition: transform 0.8s ease;

      .subtitle {
        * {
          color: $fermata_red;
          font-size: 0.833vw;
          line-height: 120%;
          margin-bottom: 1.111vw;
          text-transform: uppercase;
          @media (max-width: $breakpoint-md) {
            margin-bottom: 16px;
            font-size: 12px;
          }
        }
      }
      .title_wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 3.333vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 32px;
        }
        span {
          h1,
          h2,
          h3 {
            font-size: 3.333vw;
            font-weight: normal;
            @media (max-width: $breakpoint-md) {
              font-size: 32px;
            }
            strong {
              font-family: 'TWKLausanne-700';
            }
          }
        }
      }
    }
    .article_wrapper {
      display: flex;
      flex-flow: row nowrap;
      gap: 1.667vw;
      @media (max-width: $breakpoint-xl) {
        flex-wrap: nowrap;
        overflow-x: scroll;
        gap: 16px;
        overflow-y: hidden;
      }
    }
    .btn {
      @media (max-width: $breakpoint-md) {
        display: flex;
        align-items: center;
        max-width: max-content;
        margin: auto;
      }
      &::after {
        background-image: url('/mixed-arrow.svg');
        content: ' ';
        width: 1.9vw;
        height: 1.111vw;
        background-size: cover;
        margin-left: 0.833vw;
        display: inline-block;
        @media (max-width: $breakpoint-md) {
          width: 24px;
          height: 14px;
          margin-left: 12px;
        }
      }
    }
    .btn:active::after {
      background-size: contain;
      background-repeat: no-repeat;
      height: 0.972vw;
    }
  }
}
