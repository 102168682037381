@import '../variables.scss';

.expandedCardWrapper {
  //position: relative;
  @media (max-width: $breakpoint-md) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 16px;
    flex-flow: row wrap;
  }

  .cardWrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    transform: scale(0.8);
    transform-origin: center center;
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      flex-flow: row nowrap;
      align-items: center;
      flex: 1 1 0;
    }
    &.visible {
      transform: scale(1);
    }
    .textWrapper {
      padding: 1.111vw;
      background: $white;
      border-radius: 0.833vw;
      position: relative;
      bottom: 0;
      max-width: 95%;
      margin: 0 1.111vw 0 1.111vw;
      order: 1;
      transform: translateY(-2vw);
      @media (max-width: $breakpoint-md) {
        padding: 16px;
        border-radius: 12px;
        width: 100%;
        margin: 0;
        transform: translate(-16px, 0);
      }
      .subtitle {
        p {
          font-family: 'TWKLausanne-450';
          font-size: 0.833vw;
          line-height: 1vw;
          color: $medium_grey;
          margin-bottom: 0.278vw;
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            line-height: 14.4px;
            margin-bottom: 4px;
          }
        }
      }
      .cardtitle {
        p {
          font-family: 'TWKLausanne-700';
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: $dark_grey;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
    img {
      width: 16.667vw;
      border-radius: 0.833vw 0.833vw 0.833vw 0;
      @media (max-width: $breakpoint-md) {
        width: 40%;
        border-radius: 12px 12px 12px 0;
      }
    }
  }
  .expanded {
    position: relative;
    padding-top: 24px;
    width: 100%;
    .contentWrapper::before {
      position: absolute;
      content: ' ';
      background: $white;
      height: 24px;
      width: 24px;
      transform: rotate(45deg);
      top: -2%;
      right: 24px;
      z-index: 0;
    }
    .contentWrapper {
      position: relative;
      padding: 48px 16px;
      background: $white;
      border-radius: 24px 0 0 0;
      .close_trigger {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-24px, 24px);
        cursor: pointer;
      }
      .mainsubtitle {
        p {
          font-family: 'TWKLausanne-450';
          font-size: 0.833vw;
          line-height: 0.972vw;
          text-transform: uppercase;
          color: $fermata_red;
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      .maintitle {
        h1,
        h2,
        h3,
        h4 {
          font-family: 'TWKLausanne-700';
          font-size: 2.222vw;
          line-height: 2.667vw;
          color: $dark_grey;
          margin: 1.111vw 0 2.222vw 0;
          @media (max-width: $breakpoint-md) {
            font-size: 24px;
            line-height: 29px;
            margin: 16px 0 24px 0;
          }
        }
        .maindescription {
          p {
            font-family: 'TWKLausanne-300';
            font-size: 0.972vw;
            line-height: 1.458vw;
            color: $medium_grey;
            @media (max-width: $breakpoint-md) {
              font-size: 14px;
              line-height: 21px;
            }
          }
        }
      }
    }
    section {
      margin: 0;
      border-radius: 0;
      width: 100%;
    }
    section[class^='Benefits'] {
      div[class^='Benefits_cardsWrapper'] > div:first-child {
        margin-top: 0;
      }
      div[class^='Benefits_cardsWrapper'] > div:last-child {
        margin-bottom: 0;
      }
    }
    section[class^='Callout'] {
      div[class^='Callout_callout_content'] {
        border-radius: 0 0 24px 0;
      }
    }
  }
  .click_expand {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    background: $white;
    padding: 1.042vw;
    border-radius: 50%;
    margin: auto;
    cursor: pointer;
    @media (max-width: $breakpoint-md) {
      padding: 24px;
      margin: auto 0 auto 0;
    }
    img {
      height: 1.111vw;
      width: 1.111vw;
      @media (max-width: $breakpoint-md) {
        height: 16px;
        width: 16px;
      }
    }
  }
}
