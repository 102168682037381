@import '../variables.scss';

.accordionWrapper {
  position: relative;
  padding: 4.444vw 0;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    padding: 64px 0;
  }
  &.visible {
    opacity: 1;
    .accordionItem {
      transform: scale(1);
    }
  }
  .accordionItem {
    width: 59.444vw;
    margin: auto;
    transform: scale(0.8);
    transform-origin: center center;
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      width: 92.5%;
    }
    .contentWrapper {
      margin-bottom: 2.361vw;
      display: flex;
      flex-direction: column;
      padding: 0 1.667vw;
      box-shadow: 0 3.333vw 3.333vw 0 $box_shadow;
      border-radius: 0.833vw;
      background: $white;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 16px;
        padding: 0 24px;
        box-shadow: 0px 48px 48px 0px $box_shadow;
      }
      .titleWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 1.667vw 0;
        cursor: pointer;
        position: relative;
        @media (max-width: $breakpoint-md) {
          padding: 24px 0;
        }
        p {
          font-family: 'TWKLausanne-700';
          font-size: 1.25vw;
          line-height: 1.875vw;
          color: $dark_grey;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
            width: 85%;
          }
        }
        img {
          height: 1.667vw;
          width: 1.667vw;
          @media (max-width: $breakpoint-md) {
            height: 24px;
            width: 24px;
          }
        }
      }
      .textWrapper {
        width: 51.667vw;
        overflow: hidden;
        transition: all 0.3s ease-out;
        @media (max-width: $breakpoint-md) {
          width: 100%;
        }
        p,
        li {
          font-family: 'TWKLausanne-300';
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: $medium_grey;
          margin-bottom: 20px;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
          }
        }
        li {
          list-style: disc;
          margin-left: 1.667vw;
          @media (max-width: $breakpoint-md) {
            margin-left: 24px;
          }
        }
        a {
          text-decoration: underline;
        }
        table {
          border-spacing: 0;
          overflow: hidden;
          inline-size: 100%;
          text-align: left;
          background-color: inherit;
          border: 1px solid lightgray;
          border-radius: 0.5rem;
          box-shadow: 0px 4px 6px -2px rgba(14, 30, 37, 0.12);
        }

        :is(th, td) {
          padding: 1rem;
          border-block-end: 1px solid lightgray;

          @media (min-width: $breakpoint-md) {
            min-inline-size: 10rem;
          }
        }

        tfoot :is(th, td) {
          border-block-end: unset;
        }

        tfoot tr {
          background-color: whitesmoke;
        }

        :is(th, td):not(:first-child) {
          border-inline-start: 1px solid lightgray;
        }
        &.show {
          transition: all 0.3s ease-out;
          padding-bottom: 1.667vw;
          @media (max-width: $breakpoint-md) {
            padding-bottom: 24px;
          }
        }
      }
    }
  }
}
