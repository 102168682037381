@import '../variables.scss';

.quoteWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  background: $violet_02;
  border-radius: 2.222vw 2.222vw 2.222vw 0;
  box-shadow: 0 3.333vw 3.333vw 0 $box_shadow;
  max-width: 59.444vw;
  margin: 0 auto 3.333vw auto;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    max-width: none;
    width: auto;
    margin: 0 16px 48px 16px;
    border-radius: 32px 32px 32px 0px;
    box-shadow: 0px 48px 48px 0px $box_shadow;
  }
  &.visible {
    opacity: 1;
    .textWrapper {
      transform: translateY(0);
    }
  }
  .textWrapper {
    font-family: 'TWKLausanne-450';
    font-size: 1.667vw;
    line-height: 2vw;
    padding: 4.444vw 6.111vw;
    color: $white;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 27px;
      padding: 64px 24px;
    }
    .author {
      font-size: 0.833vw;
      line-height: 1vw;
      text-transform: uppercase;
      margin-top: 2.222vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
        margin-top: 32px;
      }
    }
  }
}
