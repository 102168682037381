@import '../variables.scss';
@import '../typography.scss';
@import '../breakpoints.scss';

.profileWrapper {
  position: relative;
  width: calc(33.3% - 20px);
  padding-top: 2vw;
  margin-right: 30px;
  transform: scale(0.8);
  transform-origin: center center;
  transition: transform 0.8s ease;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  @media (max-width: $breakpoint-md) {
    width: 100%;
    margin: 0;
  }
  &.visible {
    transform: scale(1);
  }

  &.fourCols {
    width: calc(24% - 20px);

    @media (max-width: $breakpoint-md) {
      width: 100%;
      margin: 0;
    }

    @media (max-width: $breakpoint-xxl) {
      width: calc(47% - 20px);
    }

    &:nth-of-type(2n) {
      margin-right: 0;
    }
    &:nth-of-type(3n) {
      margin-right: 30px;
    }
  }

  .profile_summary {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 0.833vw 0.833vw 0.833vw 0px;
    padding-bottom: 4vw;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .wrapper_img {
      @media (max-width: $breakpoint-md) {
        width: 106px;
        height: 106px;
        min-width: auto;
        min-height: auto;
        display: block;
      }
      img {
        object-fit: cover;
      }
    }
    .wrapper_profile_details {
      padding: 1.111vw;
      margin: -2.361vw 1.111vw 0 1.111vw;
      box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
      background: $white;
      border-radius: 0.833vw;
      z-index: 2;
      position: relative;
      @media (max-width: $breakpoint-md) {
        width: calc(100% - 180px);
        padding: 16px;
        border-radius: 12px;
        margin: 0 10px 0 -16px;
      }
      .jobtitle {
        color: $medium_grey;
        font-size: 0.833vw;
        margin-bottom: 0.278vw;
        text-transform: uppercase;
        font-family: 'TWKLausanne-450';
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          margin-bottom: 4px;
        }
      }
      .name {
        color: $dark_grey;
        font-size: 0.972vw;
        font-family: 'TWKLausanne-700';
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
        }
      }
    }
    .click_expand {
      background-color: $white;
      width: 3.333vw;
      height: 3.333vw;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 1.111vw auto 0 auto;
      cursor: pointer;
      border-radius: 6.944vw;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      @media (max-width: $breakpoint-md) {
        width: 64px;
        height: 64px;
        border-radius: 100px;
        margin: 0;
        position: relative;
        bottom: inherit;
        left: inherit;
        transform: none;
      }
      img {
        width: 0.972vw;
        height: 0.972vw;
        @media (max-width: $breakpoint-md) {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .container_detail {
    margin-top: 9.375vw;
    background: $white;
    z-index: 2;
    position: relative;
    padding: 5.556vw 6.111vw 3.472vw 6.111vw;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 2.222vw 0px 2.222vw 2.222vw;
    @media (max-width: $breakpoint-md) {
      border-radius: 32px 0px 32px 32px;
      padding: 24px;
    }
    .close_trigger {
      width: 1.667vw;
      height: 1.667vw;
      position: absolute;
      right: 1.667vw;
      top: 1.667vw;
      cursor: pointer;
      @media (max-width: $breakpoint-md) {
        width: 24px;
        height: 24px;
        top: 24px;
        right: 24px;
      }
    }
    .box_header {
      display: flex;
      gap: 1.667vw;
      margin-bottom: 2.222vw;
      @media (max-width: $breakpoint-md) {
        gap: 24px;
        margin-bottom: 32px;
      }
      span {
        width: 4.444vw;
        height: 4.444vw;
        @media (max-width: $breakpoint-md) {
          width: 64px;
          height: 64px;
        }
      }
      .right_area {
        .jobtitle {
          color: $fermata_red;
          font-size: 0.833vw;
          margin-bottom: 1.111vw;
          line-height: 120%;
          font-family: 'TWKLausanne-450';
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            margin-bottom: 16px;
          }
        }
        .name {
          font-size: 1.667vw;
          color: $dark_grey;
          font-family: 'TWKLausanne-700';
          @media (max-width: $breakpoint-md) {
            font-size: 24px;
          }
        }
      }
    }
    .box_content {
      color: $medium_grey;
      font-size: 0.972vw;
      font-family: 'TWKLausanne-300';
      line-height: 150%;
      @media (max-width: $breakpoint-md) {
        font-size: 14px;
      }
    }
  }
}
