@import '../breakpoints.scss';
@import '../variables.scss';

.section {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  padding: 3.33vw 10.278vw;
  overflow: hidden;
  opacity: 0;
  transition: opacity 0.8s ease;
  background: $fermata_red;
  background: $hover_grey;
  @media (max-width: $breakpoint-md) {
    flex-flow: column-reverse nowrap;
    padding: 34px 16px;
  }
  &.visible {
    opacity: 1;
    .content {
      transform: translateY(0);
    }
    .image_container {
      transform: scale(1);
    }
  }
  .content {
    max-width: 28.889vw;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    margin-right: 3.667vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      margin-top: 24px;
      max-width: 100vw;
      margin-right: 0;
    }
    .title {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 24px;
      }
      h2 {
        font-family: 'TWKLausanne-300';
        font-size: 3.333vw;
        font-weight: 700;
        line-height: 4vw;
        @media (max-width: $breakpoint-md) {
          font-size: 32px;
          line-height: 38.4px;
          max-width: 100%;
        }
        strong {
          font-family: 'TWKLausanne-700';
        }
      }
    }
    .description {
      font-family: 'TWKLausanne-300';
      margin: 1.5vw 0;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 24px;
      }
      p {
        font-size: 0.97vw;
        line-height: 1.5;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .imagesWrapper{
    display: flex;
    gap: 10px;
    max-height: 350px;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      max-height: 100%;
      flex-flow: column;
      gap: 0;
    }
    .image_container {
      flex: 1 1 auto;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        max-width: 100%;
      }
      img {
        vertical-align: middle;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}