@import '../breakpoints.scss';
@import '../variables.scss';

.radioGroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  max-width: 34.6vw;

  @media (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 100%;
  }

  label {
    width: 100%;
    aspect-ratio: 1;
    border: 2px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    @media (max-width: $breakpoint-md) {
      height: 100px;
    }
  }

  input[type='radio'] {
    position: absolute;
    opacity: 0;
    width: 100% !important;
    height: 100%;
    cursor: pointer;
  }

  span {
    font-size: 1vw;
    white-space: nowrap;

    @media (max-width: $breakpoint-md) {
      font-size: 18px;
    }
  }
}

.imageWrapper {
  display: flex;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--neutral-white, #fff);
  box-shadow: 0px 2px 17px 0px rgba(25, 27, 37, 0.1);
  border: 2px solid transparent;

  @media (max-width: $breakpoint-md) {
    flex-direction: row;
    justify-content: flex-start;
    height: 100px;
    gap: 10px;
    padding: 10px;
  }
}

.radioOption img {
  max-width: 80%;
  max-height: 80%;
  padding: 10px;
  height: auto !important;

  @media (max-width: $breakpoint-md) {
    max-width: 80px;
    max-height: 80px;
  }
}

.selected {
  border: 2px solid var(--primary-fermata-violet, #644bfb);

  span {
    font-weight: bold;
  }
}
