@import '../variables.scss';

.newsListingWrapper {
  position: relative;
  margin: 4.444vw 0;
  opacity: 0;
  @media (max-width: $breakpoint-md) {
    margin: 0 0 48px 0;
  }
  &.visible {
    opacity: 1;
    .articleWrapper .article {
      transform: scale(1);
    }
  }
  .tagLine {
    font-family: 'TWKLausanne-300';
    font-size: 1.25vw;
    line-height: 1.875vw;
    color: $medium_grey;
    width: 71.667vw;
    margin: 0 auto 3.333vw auto;
    @media (max-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 27px;
      width: 92.5%;
      margin: 0 auto 32px auto;
    }
  }
  .articleWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px 16px;
    justify-content: space-evenly;
    width: 71.667vw;
    margin: auto;
    @media (max-width: $breakpoint-xl) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: $breakpoint-xl) {
      gap: 16px;
    }
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      width: 92.5%;
      margin-top: 48px;
    }
    .article {
      width: 100%;
      height: fit-content;
      display: none;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
      &.active {
        display: block;
      }
    }
    .article:nth-child(3n) {
      margin-right: 0;
    }
  }
  .btnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4.444vw;
    @media (max-width: $breakpoint-md) {
      margin-top: 48px;
    }
    .btn {
      display: none;
      &.visible {
        display: flex;
        align-items: center;
      }
      &.visible.visibleTagOverride {
        display: flex;
        align-items: center;
      }
      &.visible.invisibleTagOverride {
        display: none;
      }
    }
  }
  .visible::after {
    background-image: url('/mixed-arrow.svg');
    content: ' ';
    width: 1.806vw;
    height: 1.111vw;
    background-size: cover;
    margin-left: 0.833vw;
    display: inline-block;
    @media (max-width: $breakpoint-md) {
      width: 24px;
      height: 14px;
      margin-left: 10px;
    }
  }
}
