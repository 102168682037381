@import '../variables.scss';

.callout_section {
  position: relative;
  opacity: 0;
  transition: opacity 0.8s ease;

  @media (max-width: $breakpoint-md) {
    margin-bottom: 64px;
  }

  &.visible {
    opacity: 1;

    .callout_content>.callout_title {
      transform: translate(0);
    }

    .callout_content>.calloutbtn {
      transform: translate(0);
    }

    .callout_content>.background {
      transform: scale(1);
    }
  }

  .callout_content {
    margin: auto;
    padding: 2.556vw 14.167vw;
    display: flex;
    gap: 20vw;
    align-items: center;
    position: relative;

    @media (max-width: $breakpoint-md) {
      max-width: 91.467vw;
      padding: 10.556vw 9.167vw;
      gap: 0;
      flex-direction: column;
      align-items: flex-start;
      min-height: 285px;
      justify-content: center;
      border-radius: 32px 32px 32px 0;
    }

    &.callout_orange {
      background: $red_orange_50_gradient;
    }

    &.callout_red {
      background: $fermata_red;
    }

    &.callout_violet {
      background: $violet_gradient;
    }

    &.callout_white {
      background: $white;

      .callout_title {
        color: $dark_grey;
      }
    }

    &.callout_light_grey {
      background: $light_grey;

      .callout_title {
        color: $dark_grey;
      }
    }

    &.callout_dark_grey {
      background: $dark_grey;
    }

    .background {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 2.222vw 2.222vw 2.222vw 0;

        @media (max-width: $breakpoint-md) {
          border-radius: 32px 32px 32px 0;
        }
      }
    }

    .callout_title {
      font-family: 'TWKLausanne-300';
      font-size: 2.222vw;
      font-weight: 700;
      line-height: 2.667vw;
      color: $white;
      position: relative;
      max-width: 60%;
      transform: translateY(20px);
      transition: transform 0.8s ease;
      h1,
      h2,
      h3 {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
      strong {
        font-family: 'TWKLausanne-700';
      }
      @media (max-width: $breakpoint-md) {
        font-size: 24px;
        font-weight: 700;
        line-height: 28.8px;
        margin-bottom: 24px;
        max-width: 100%;
      }
      span {
        font-weight: 400;
      }
    }

    .callout_info_wrapper {
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: center;
      max-width: 20vw;
      gap: 3vw;

      @media (max-width: $breakpoint-md) {
        max-width: 100%;
        gap: 10vw;
      }

      .callout_description {
        font-family: 'TWKLausanne-300';
        font-size: 1vw;
        font-weight: 400;
        line-height: 1.667vw;
        color: $white;
        position: relative;
        width: 100%;
        transform: translateY(20px);
        transition: transform 0.8s ease;
        @media (max-width: $breakpoint-md) {
          font-size: 4vw;
          line-height: 5vw;
        }

        p {
          font-family: inherit;
          font-size: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
      }

      .calloutbtn {
        text-align: center;
        position: relative;
        min-width: 16.667vw;

        @media (max-width: $breakpoint-md) {
          text-align: left;
          min-width: 175px;
        }

        span {
          display: inline-block;
          font-size: 1vw;

          @media (max-width: $breakpoint-md)  {
            font-size: 4vw;
          }
        }

        button {
          display: flex;
          align-items: center;
          background-color: white;
          color: black;
          border-radius: 15px;
        }

        .btn::after {
          background-image: url('/right-simplified-arrow-black.svg');
          content: ' ';
          width: 1.9vw;
          height: 1.111vw;
          background-size: cover;
          margin-left: 0.833vw;
          display: inline-block;

          @media (max-width: $breakpoint-md) {
            width: 24px;
            height: 14px;
            margin-left: 12px;
          }
        }
      }
    }

  }
}