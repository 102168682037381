@import '../variables.scss';
@import '../typography.scss';
@import '../breakpoints.scss';

.anchorLinksWrapper {
  z-index: 3;
  position: relative;
  display: flex;
  justify-content: space-around;
  position: sticky;
  top: 6.667vw;
  padding: 1.181vw 2vw;
  background-color: $light_grey;
  @media (max-width: $breakpoint-md) {
    padding: 0 16px;
    top: 96px;
    overflow: hidden;
  }
  &::after {
    @media (max-width: $breakpoint-md) {
      pointer-events: none;
      content: '';
      width: 90px;
      height: 100%;
      position: absolute;
      right: 0;
      background: linear-gradient(
        270deg,
        #f9f7f9 0%,
        rgba(249, 247, 249, 0) 100%
      );
    }
  }
  .wrapperAnchor {
    max-width: 59.444vw;
    margin: auto;
    display: flex;
    gap: 1.667vw;
    position: relative;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      width: 100%;
      gap: 16px;
      position: unset;
      overflow-y: scroll;
      scrollbar-width: none; /* removes scrollbar for Firefox */
      -ms-overflow-style: none; /* removes scrollbar for IE and Edge */
    }
    &::after {
      content: '';
      background: #eeeaee;
      border-radius: 0px 0px 2.222vw 2.222vw;
      position: absolute;
      height: 0.208vw;
      width: 100%;
      bottom: -1.308vw;
      z-index: 3;
      @media (max-width: $breakpoint-md) {
        border-radius: 0px 0px 32px 32px;
        height: 3px;
        bottom: 0;
        left: 0;
      }
    }
    a {
      display: block;
      text-align: center;
      text-transform: uppercase;
      font-size: 0.833vw;
      font-family: 'TWKLausanne-700';
      color: $medium_grey;
      white-space: nowrap;
      position: relative;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        padding: 17px 0;
        color: $medium_grey;
      }
      &.active {
        color: $discharging;
        &::after {
          content: '';
          display: block;
          position: absolute;
          height: 0.208vw;
          width: calc(100% + 1.4vw);
          left: -0.7vw;
          border-radius: 0px 0px 2.222vw 2.222vw;
          background: $discharging;
          bottom: -1.308vw;
          z-index: 4;
          @media (max-width: $breakpoint-md) {
            height: 3px;
            width: 100%;
            border-radius: 0px 0px 32px 32px;
            bottom: 0;
            z-index: 4;
            left: unset;
          }
        }
      }
    }
  }
  .wrapperAnchor::-webkit-scrollbar {
    display: none; /* removes scrollbar for Chrome, Safari, Opera */
  }
}
