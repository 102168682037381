@import '../variables.scss';

.footer_menu {
  color: $white;
  font-size: 0.972vw;
  line-height: 1.458vw;
  font-weight: 300;
  @media (max-width: $breakpoint-md) {
    font-size: 14px;
    line-height: 21px;
  }
  .footer_menu_list {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-around;
    height: 10.625vw;
    width: 35vw;
    @media (max-width: $breakpoint-md) {
      height: auto;
      width: 164px;
    }
    .footer_menu {
      padding: 0.556vw 1.667vw 0.556vw 0;
      @media (max-width: $breakpoint-md) {
        padding: 0 16px 8px 0;
      }
      a {
        display: flex;
        align-items: center;
        flex-direction: row;
        align-content: center;
      }
      p.hasIcon {
        padding-left: 0.694vw;
        @media (max-width: $breakpoint-md) {
          padding-left: 8px;
        }
      }
    }
    .hide_mobile {
      padding: 0.764vw 0 0.764vw 0;
      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }
  }
}
