@import '../variables.scss';

.legal_menu {
  color: $light_grey_70;
  .legal_list {
    display: flex;
    flex-flow: row nowrap;
    li {
      margin-right: 0.694vw;
    }
    li:nth-child(1) {
      :after {
        content: '/';
        margin-left: 0.694vw;
      }
    }
  }
}
