@import '../variables.scss';

.benefitsWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 71.667vw;
  margin: 4.444vw auto 0 auto;
  padding: 4.444vw 6.111vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    width: 92.5%;
    margin: auto;
    background: $white;
    padding: 48px 16px;
  }
  &.visible {
    opacity: 1;
    .textWrapper {
      transform: translateY(0);
    }
    .cardsWrapper {
      .benefitsItem {
        transform: scale(1);
      }
    }
  }
  .textWrapper {
    max-width: 48.5%;
    margin-right: 1.875vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      margin-right: 0;
    }
    .subtitle > p {
      font-family: 'TWKLausanne-450';
      font-size: 0.833vw;
      line-height: 1vw;
      text-transform: uppercase;
      color: $fermata_red;
      margin-bottom: 0.694vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
        margin-bottom: 10px;
      }
    }
    .title > p {
      font-family: 'TWKLausanne-700';
      font-size: 2.222vw;
      line-height: 2.667vw;
      color: $dark_grey;
      margin-bottom: 1.667vw;
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 24px;
      }
    }
    .description > p {
      font-family: 'TWKLausanne-300';
      font-size: 1.25vw;
      line-height: 1.875vw;
      color: $medium_grey;
      margin-bottom: 0.694vw;
      @media (max-width: $breakpoint-md) {
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 32px;
      }
    }
  }
  .cardsWrapper {
    .benefitsItem {
      display: flex;
      align-items: center;
      background: $white;
      margin: 1.111vw 0;
      padding: 1.111vw;
      box-shadow: 0 3.333vw 3.333vw 0 $box_shadow;
      border-radius: 0.833vw;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 16px;
        padding: 16px;
        box-shadow: 0px 48px 48px 0px $box_shadow;
        border-radius: 12px;
      }
      .iconWrapper {
        min-width: 3.333vw;
        @media (max-width: $breakpoint-md) {
          min-width: 48px;
        }
        span {
          vertical-align: middle;
        }
      }
      p {
        font-family: 'TWKLausanne-300';
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: $medium_grey;
        margin-left: 1.667vw;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
          margin-left: 16px;
        }
      }
    }
  }
}
