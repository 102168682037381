@import '../variables.scss';
@import '../typography.scss';

.calculatorForm {
  display: flex;
  gap: 32px;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    gap: 0;
  }
}

.calculatorSection {
  padding: 5.556vw 14.167vw 0;

  @media (max-width: $breakpoint-md) {
    padding: 16px 10px;
  }

  .calculatorTitles {
    p {
      color: var(--neutral-grey-300, #aca8b5);
      font-family: 'TWKLausanne-700';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      font-size: 1.367vw;
      line-height: 150%; /* 36px */

      @media (max-width: $breakpoint-md) {
        font-size: 18px;
        line-height: 150%; /* 30px */
      }
    }

    h4 {
      color: var(--primary-fermata-red, #e80c26);
      font-family: 'TWKLausanne-700';
      font-size: 32px;
      font-size: 1.79vw;
      line-height: 1.82vw;
      font-style: normal;
      font-weight: 700;
      line-height: 150%; /* 48px */
      margin: 12px 0 0;
      text-wrap: balance;

      @media (max-width: $breakpoint-md) {
        font-size: 24px;
        line-height: 150%; /* 36px */
      }
    }
  }

  .step {
    color: var(--neutral-grey-900, #191b25);
    font-family: 'TWKLausanne-700';
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }

  form {
    padding: 1.667vw 0 8.333vw 0;
    @media (max-width: $breakpoint-md) {
      padding: 32px 0 68px 0;
    }
  }

  .formRow {
    display: flex;
    flex-direction: space-between;
    gap: 1.667vw;
    @media (max-width: $breakpoint-md) {
      gap: 24px;
      flex-direction: column;
      margin-bottom: 24px;
    }

    &.sliderRow {
      width: 90%;

      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    &.radioRow {
      width: 100%;
    }

    .formItem {
      width: 100%;

      label,
      .select_label {
        display: block;
        font-size: 0.972vw;
        margin-bottom: 0.556vw;
        color: $dark_grey;
        font-family: 'TWKLausanne-300';
        line-height: 150%;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      .select_label {
        @media (max-width: $breakpoint-md) {
          margin-top: 24px;
        }
      }
      input,
      textarea,
      select {
        padding: 0.833vw 1.111vw;
        font-size: 0.972vw;
        line-height: 150%;
        background: $light_grey;
        border: 0;
        box-shadow: inset 2px 0px 8px 1.5px rgba(31, 25, 101, 0.04);
        border-radius: 0.278vw;
        width: 100%;
        color: $medium_grey;
        @media (max-width: $breakpoint-md) {
          padding: 12px 16px;
          border-radius: 4px;
          font-size: 14px;
          min-height: 45px;
        }
        &:focus-visible {
          border: none;
          outline: none;
        }
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('/select-arrow.svg');
        background-size: 1.111vw;
        background-position: 95% center;
        background-repeat: no-repeat;
        width: 328px;
        @media (max-width: $breakpoint-md) {
          width: 100%;
          min-width: 280px;
          background-size: 16px;
          background-position: 95% center;
        }
      }
      option:not(:first-of-type) {
        color: $dark_grey;
      }
      textarea {
        resize: none;
      }
    }
    .submit_container {
      background: $red_orange_75_gradient;
      padding-right: 2.222vw;
      border-radius: 6.944vw;
      width: max-content;
      margin: 0 auto;
      @media (max-width: $breakpoint-md) {
        padding-right: 0;
        border-radius: 100px;
        margin: auto;
      }
      .submit_btn {
        background-image: url('/mixed-arrow.svg');
        padding: 1.111vw 2.222vw;
        background-repeat: no-repeat;
        border: 0;
        background-position: right center;
        background-color: transparent;
        cursor: pointer;
        color: $white;
        font-family: 'TWKLausanne-450';
        font-size: 1.25vw;
        background-size: 1.806vw 1.111vw;
        @media (max-width: $breakpoint-md) {
          background-size: 26px 16px;
          padding: 16px 24px;
          font-size: 14px;
          font-size: 16px;
          padding-right: 60px;
          background-position: 190px center;
          width: 100%;
        }
      }
    }
  }
}

.calculatorSubmitWrapper {
  display: flex;
  width: 25vw;
  padding: 32px 24px;
  flex-direction: column;
  gap: 32px;
  background: var(--neutral-white, #fff);
  position: sticky;
  top: 120px;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    padding: 0 16px;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;
  }

  h3 {
    color: var(--neutral-grey-400, #918b9d);
    font-family: 'TWKLausanne-700';
    font-size: 1.35vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    @media (max-width: $breakpoint-md) {
      font-size: 18px;
    }
  }

  .resultsWrapper {
    display: flex;
    padding: 1.6vw 1.2vw;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-radius: 16px;
    background: #dedcec;

    @media (max-width: $breakpoint-md) {
      padding: 24px 18px;
    }

    p {
      color: var(--neutral-grey-900, #191b25);
      font-family: 'TWKLausanne-700';
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      &.value {
        font-size: 24px;
        font-weight: 450;
        font-family: 'TWKLausanne-450';
      }
    }
  }

  .disclaimer {
    margin-top: 0.65vw;
    color: var(--neutral-grey-800, #363644);
    font-family: 'TWKLausanne-300';
    font-size: 0.65vw;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;

    @media (max-width: $breakpoint-md) {
      margin-top: 10px;
      font-size: 12px;
    }
  }
}

.calculatorFormWrapper {
  position: relative;
  max-width: 100%;
  border-radius: 36px;
  background: var(--neutral-white, #fff);
  display: flex;
  width: 51.8vw;
  padding: 36px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;

  @media (max-width: $breakpoint-md) {
    max-width: 100%;
    padding: 48px 16px;
    width: 100%;
    border-radius: 32px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
