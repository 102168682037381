@import '../variables.scss';

.threeColumnStatCardsWrapper {
  position: relative;
  padding: 8.333vw 14.167vw;
  background: $red_orange_50_gradient;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0;
  transform: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    padding: 64px 16px;
    justify-content: center;
  }
  &.visible {
    opacity: 1;
    .textWrapper {
      transform: translateY(0);
    }
    .btnWrapper {
      transform: translateY(0);
    }
  }
  .textWrapper {
    margin-bottom: 3.333vw;
    max-width: 45.222vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 48px;
      max-width: 100%;
    }
    .subtitle {
      font-family: 'TWKLausanne-450';
      font-size: 0.833vw;
      line-height: 1vw;
      color: $light_grey_70;
      text-transform: uppercase;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
      }
      p {
        margin-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 16px;
        }
      }
    }
    .title {
      font-family: 'TWKLausanne-700';
      font-size: 2.222vw;
      line-height: 2.667vw;
      color: $white;
      @media (max-width: $breakpoint-md) {
        font-family: 'TWKLausanne-450';
        font-size: 32px;
        line-height: 38.4px;
      }
    }
  }
  .btnWrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3.333vw;
    max-width: 24.444vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      order: 3;
      margin: 64px 0 0 0;
      max-width: 375px;
    }
    .btn::after {
      background-image: url('/mixed-arrow.svg');
      content: ' ';
      width: 1.9vw;
      height: 1.111vw;
      background-size: cover;
      margin-left: 0.833vw;
      display: inline-block;
      @media (max-width: $breakpoint-md) {
        width: 24px;
        height: 14px;
        margin-left: 12px;
      }
    }
    .secondary_white:hover::after {
      background-image: url('/mixed-arrow-red.svg');
    }

    .secondary_white:active::after {
      background-image: url('/mixed-arrow-active.svg');
      background-size: contain;
      background-repeat: no-repeat;
      height: 0.972vw;
    }
  }
  .cardsWrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.667vw;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  }
  .cardsWrapper > div {
    width: 22.778vw;
    @media (max-width: $breakpoint-md) {
      width: 95.8%;
    }
  }
  .cardsWrapper > div > div > div:nth-child(3) {
    font-size: 1.667vw;
    line-height: 2vw;
    @media (max-width: $breakpoint-md) {
      font-size: 24px;
      line-height: 28.8px;
    }
  }
}
