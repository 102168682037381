@import '../variables.scss';

.expandableCardsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  padding: 4.444vw 14.167vw 8.333vw 14.167vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    justify-content: center;
    padding: 64px 16px;
  }
  &.visible {
    opacity: 1;
    .textWrapper {
      transform: translateY(0);
    }
  }
  .cardsWrapper {
    display: flex;
    justify-content: center;
    gap: 1.667vw;
    padding: 0 6.25vw;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      gap: 0;
      padding: 0;
      width: 100%;
    }
  }
  .textWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 3.333vw;
    padding: 0 6.25vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 32px;
      padding: 0;
    }
    .subtitle {
      p {
        font-family: 'TWKLausanne-450';
        font-size: 0.833vw;
        line-height: 0.972vw;
        text-transform: uppercase;
        color: $fermata_red;
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
    .title {
      h1,
      h2,
      h3,
      h4 {
        font-family: 'TWKLausanne-700';
        font-size: 3.333vw;
        line-height: 4vw;
        color: $dark_grey;
        text-align: center;
        margin: 1.111vw 0;
        @media (max-width: $breakpoint-md) {
          font-size: 32px;
          line-height: 38px;
          margin: 16px 0 24px 0;
        }
      }
    }
    .description {
      p {
        font-family: 'TWKLausanne-300';
        font-size: 1.25vw;
        line-height: 1.875vw;
        color: $medium_grey;
        text-align: center;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
  }
  .expanded {
    padding-top: 2.222vw;
    @media (max-width: $breakpoint-md) {
      padding-top: 24px;
    }
    .contentWrapper::before {
      position: absolute;
      content: ' ';
      background: $white;
      height: 1.667vw;
      width: 1.667vw;
      transform: rotate(45deg);
      top: -2%;
      right: 49%;
      z-index: 0;
    }
    .contentWrapper {
      position: relative;
      padding: 5.556vw 6.111vw 4.444vw 6.111vw;
      background: $white;
      border-radius: 2.222vw 0 0 0;
      @media (max-width: $breakpoint-md) {
        padding: 48px 16px;
        border-radius: 24px 0 0 0;
      }
      .close_trigger {
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(-1.667vw, 1.667vw);
        cursor: pointer;
        @media (max-width: $breakpoint-md) {
          transform: translate(-24px, 24px);
        }
      }
      .mainsubtitle {
        p {
          font-family: 'TWKLausanne-450';
          font-size: 0.833vw;
          line-height: 0.972vw;
          text-transform: uppercase;
          color: $fermata_red;
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
      .maintitle {
        h1,
        h2,
        h3,
        h4 {
          font-family: 'TWKLausanne-700';
          font-size: 2.222vw;
          line-height: 2.639vw;
          color: $dark_grey;
          margin: 1.111vw 0 2.222vw 0;
          @media (max-width: $breakpoint-md) {
            font-size: 24px;
            line-height: 29px;
            margin: 16px 0 24px 0;
          }
        }
      }
      .maindescription {
        p {
          font-family: 'TWKLausanne-300';
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: $medium_grey;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
          }
        }
      }
    }
  }
  section {
    margin: 0;
    border-radius: 0;
  }
  section[class^='Benefits'] {
    align-items: flex-start;
    div[class^='Benefits_cardsWrapper'] > div:first-child {
      margin-top: 0;
    }
    div[class^='Benefits_cardsWrapper'] > div:last-child {
      margin-bottom: 0;
    }
  }
  section[class^='Callout'] {
    div[class^='Callout_callout_content'] {
      border-radius: 0 0 2.222vw 2.222vw;
      @media (max-width: $breakpoint-md) {
        max-width: 100%;
        border-radius: 0 0 24px 0;
      }
    }
  }
}
