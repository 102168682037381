@import '../variables.scss';
.sliderContainer {
  position: relative;
  width: 100%;

  @media (min-width: $breakpoint-md) {
    width: 100%;
  }
}

.slider {
  width: 100%;
  margin: 10px 0;
}

.valueDisplay {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
}

/* Slider.module.css */

.root {
  position: relative;
  display: flex;
  width: 100%;
  user-select: none;
  align-items: center;
  padding: 24px 0;
}

.track {
  position: relative;
  height: 4px;
  width: 100%;
  overflow: hidden;
  border-radius: 18px;
  background: var(--neutral-grey-100, #e1dee4);
}

.range {
  position: absolute;
  height: 100%;
  background-color: var(--primary-fermata-violet, #644bfb);
}

.thumb {
  display: block;
  height: 24px;
  width: 24px;
  border-radius: 9999px;
  border: 2px solid var(--primary-fermata-violet, #644bfb);
  background-color: var(--primary-fermata-violet, #644bfb);
  transition: colors 0.15s ease-in-out;

  div:first-of-type {
    position: absolute;
    top: -23px;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    background-color: #644bfb;
    display: flex;
    padding: 4px 12px;
    z-index: 2;
    color: var(--neutral-grey-50, #f9f7f9);
    font-family: 'TWKLausanne-700';
    font-size: 14px;
  }

  div:last-of-type {
    position: absolute;
    top: -26px;
    left: 4px;
    width: 16px;
    height: 16px;
    background-color: #644bfb;
    rotate: 45deg;
  }
}

.thumb:focus-visible {
  outline: none;
  box-shadow: 0 0 0 2px var(--color-ring);
}

.thumb:disabled {
  pointer-events: none;
  opacity: 0.5;
}

.minmax,
.markers {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  width: 100%;
}

.minmax {
  font-size: 12px;
  color: var(--neutral-grey-500, #a8a6b2);

  span {
    display: flex;
    padding: 4px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: var(--neutral-grey-50, #f9f7f9);
  }
}

.markers {
  color: var(--neutral-grey-200, #c7c3cd);
  text-align: center;
  font-family: 'TWKLausanne-300';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-transform: uppercase;

  span {
    position: relative;
    padding-top: 12px;
    width: 24px;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 12px;
      background: var(--neutral-grey-200, #c7c3cd);
    }
  }
}
