@import '../variables.scss';

.social_menu {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  @media (max-width: $breakpoint-md) {
    flex-flow: column;
  }
  .social_title {
    color: white;
    font-size: 0.972vw;
    line-height: 1.458vw;
    font-weight: 700;
    font-family: 'TWKLausanne-700';
    margin-right: 1.597vw;
    @media (max-width: $breakpoint-md) {
      font-size: 14px;
      line-height: 21px;
      padding-bottom: 35px;
      margin-right: 0;
    }
  }
  .social_list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    li {
      margin-right: 0.556vw;
      @media (max-width: $breakpoint-md) {
        margin-right: 8px;
      }
    }
  }
  .social_list > li:last-child {
    margin-right: 0;
  }
}
