@import '../breakpoints.scss';
@import '../variables.scss';

.videoBlockSection {
  padding: 100px 10.278vw;
  margin: 0;
  position: relative;
  display: flex;
  gap: 60px;
  align-items: center;
  flex-direction: row;
  background: white;
  @media (max-width: $breakpoint-md) {
    margin-bottom: 0;
    padding: 50px 20px;
    flex-wrap: wrap;
    flex-direction: column;
    &.reverse {
      flex-direction: column-reverse;
    }
  }
  &.fullWidth {
    padding-bottom: 0px;
    .image {
      width: 100%;
      img {
        width: 100vw;
        @media (max-width: $breakpoint-md) {
          min-height: 600px; // height only needed for fullwidth hero
          object-fit: cover;
        }
      }
    }
  }
  .videoTitle {
    flex: 1;
    font-family: 'TWKLausanne-700';
    font-size: 1.8vw;
    line-height: 3.111vw;

    @media (max-width: $breakpoint-xl) {
      font-size: 1.444vw;
      line-height: 3vw;
    }

    @media (max-width: $breakpoint-md) {
      font-size: 28px;
      line-height: 48px;
    }

    strong {
      font-family: 'TWKLausanne-700';
    }
  }
  .image {
    flex: 1.5;
    width: 100%;
    height: auto;
    position: relative;
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
      border-radius: .8rem;
      @media (max-width: $breakpoint-md) {
        max-height: 210px; // changed for halfwidth hero
        object-fit: cover;
      }
    }

    .imagePlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;

      .imagePlayWrapper {
        background: $red_orange_50_gradient;
        background-size: 100%;
        border-radius: 2.222vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        transition: linear 0.3s;
        @media (max-width: $breakpoint-md) {
          width: 48px;
          border-radius: 32px;
          height: 48px;
          position: absolute;
        }

        @media (min-width: $breakpoint-md) {
          &:hover {
            border-radius: 2.4vw;
            background-size: 200%;
            box-shadow: 0px 4px 24px -16px rgba(167, 0, 20, 0.25);
            cursor: pointer;
            img {
              width: 2.4vw;
              height: 2.4vw;
            }
          }
        }

        img {
          width: 2.222vw;
          height: 2.222vw;
          margin: 1.111vw;
          transition: linear 0.3s;
          cursor: pointer;
          @media (max-width: $breakpoint-md) {
            width: 24px;
            height: 24px;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
        }
      }

      @media (max-width: $breakpoint-md) {
        width: 100%;
        height: 100%;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
