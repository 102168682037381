@import '../variables.scss';

.threeColumnCaseStudyFeedWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding: 8.333vw 14.167vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    justify-content: center;
    padding: 64px 0;
  }
  &.visible {
    opacity: 1;
    .textWrapper {
      transform: translateY(0);
    }
    .btnWrapper {
      transform: translateY(0);
    }
  }

  .textWrapper {
    margin-bottom: 3.333vw;
    width: 47.222vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      margin-bottom: 48px;
      width: 100%;
      padding: 0 16px;
    }
    .subtitle {
      font-family: 'TWKLausanne-450';
      font-size: 0.833vw;
      line-height: 1vw;
      color: $fermata_red;
      text-transform: uppercase;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
      }
      p {
        margin-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 16px;
        }
      }
    }
    .title {
      font-family: 'TWKLausanne-700';
      font-size: 2.222vw;
      line-height: 2.667vw;
      color: $dark_grey;
      @media (max-width: $breakpoint-md) {
        font-family: 'TWKLausanne-450';
        font-size: 32px;
        line-height: 38.4px;
      }
    }
  }
  .btnWrapper {
    display: flex;
    align-items: flex-end;
    margin-bottom: 3.333vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      order: 3;
      margin: 64px 0 0 0;
    }
    .btn::after {
      background-image: url('/mixed-arrow.svg');
      content: ' ';
      width: 1.9vw;
      height: 1.111vw;
      background-size: cover;
      margin-left: 0.833vw;
      display: inline-block;
      @media (max-width: $breakpoint-md) {
        width: 24px;
        height: 14px;
        margin-left: 12px;
      }
    }
  }
  .article_wrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.667vw;
    @media (max-width: $breakpoint-md) {
      display: block;
      width: 95%;
      align-items: center;
      padding-right: 16px;
      padding-left: 16px;
      gap: 0;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      scroll-snap-type: x mandatory;
    }
  }

  .article_wrapper::-webkit-scrollbar {
    display: none;
  }
}
