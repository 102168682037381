@import '../variables.scss';
@import '../breakpoints.scss';
@import '../typography.scss';

.comparisonTableWrapper {
  position: relative;
  padding-bottom: 8.333vw;
  padding-top: 8.333vw;
  background: $white;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    padding-bottom: 64px;
    padding-top: 64px;
  }
  &.visible {
    opacity: 1;
    .header_wrapper {
      transform: translateY(0);
    }
    .mainWrapper {
      transform: scale(1);
    }
  }
  .header_wrapper {
    max-width: 47.222vw;
    padding-bottom: 3.333vw;
    text-align: center;
    margin: auto;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      text-align: left;
      padding: 0 16px 48px;
    }
    .subtitle {
      font-size: 0.833vw;
      line-height: 120%;
      color: $fermata_red;
      font-family: 'TWKLausanne-450';
      margin-bottom: 1.111vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        margin-bottom: 16px;
      }
    }
    .title {
      font-size: 3.333vw;
      color: $dark_grey;
      line-height: 120%;
      font-family: 'TWKLausanne-700';
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
      }
    }
  }
  .mainWrapper {
    display: flex;
    flex-direction: column;
    max-width: 71.667vw;
    margin: auto;
    transform: scale(0.8);
    transform-origin: center center;
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      padding: 0 16px;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none;
    }
    .internalWrapper {
      display: flex;
      justify-content: space-between;
      gap: 1.667vw;
      @media (max-width: $breakpoint-md) {
        gap: 16px;
      }
      &:first-child {
        .left,
        .right {
          border-radius: 2.222vw 2.222vw 0 0;
          @media (max-width: $breakpoint-md) {
            border-radius: 24px 24px 0 0;
          }
        }
      }
      &:last-child {
        .left,
        .right {
          border-radius: 0 0 2.222vw 2.222vw;
          padding-bottom: 5.556vw;
          @media (max-width: $breakpoint-md) {
            border-radius: 0 0 24px 24px;
            padding-bottom: 64px;
          }
        }
      }
      .left,
      .right {
        width: 35vw;
        background: $light_grey;
        @media (max-width: $breakpoint-md) {
          width: 50%;
          min-width: 253px;
        }
        .boxSubtitle {
          padding: 5.556vw 6.111vw 1.111vw;
          font-size: 0.833vw;
          font-family: 'TWKLausanne-700';
          color: $medium_grey;
          text-transform: uppercase;
          line-height: 120%;
          @media (max-width: $breakpoint-md) {
            padding: 64px 16px 16px;
            font-size: 12px;
          }
        }
        .boxTitle {
          padding: 0 6.111vw 2.222vw;
          color: $discharging;
          font-size: 1.667vw;
          font-family: 'TWKLausanne-700';
          line-height: 120%;
          @media (max-width: $breakpoint-md) {
            padding: 0 16px 32px;
            font-size: 24px;
          }
        }
        .boxDesc {
          padding: 0 6.111vw 2.222vw;
          color: $dark_grey;
          font-family: 'TWKLausanne-300';
          font-size: 0.972vw;
          line-height: 150%;
          @media (max-width: $breakpoint-md) {
            padding: 0 16px 32px;
            font-size: 14px;
          }
        }
      }
      .card {
        display: flex;
        padding: 0 6.111vw 1.111vw;
        @media (max-width: $breakpoint-md) {
          padding: 16px;
        }
        .wrapperCard {
          background-color: $white;
          width: 100%;
          box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
          border-radius: 0.833vw;
          padding: 1.111vw;
          display: flex;
          gap: 1.667vw;
          align-items: center;
          @media (max-width: $breakpoint-md) {
            border-radius: 12px;
            gap: 16px;
            padding: 16px;
          }
        }
        .image {
          width: 3.333vw;
          height: 3.333vw;
          position: relative;
          @media (max-width: $breakpoint-md) {
            width: 48px;
            height: 48px;
          }
          span {
            min-width: 100%;
            min-height: 100%;
          }
        }
        .description {
          width: calc(100% - 3.333vw);
          font-size: 0.972vw;
          color: $dark_grey;
          font-family: 'TWKLausanne-300';
          line-height: 150%;
          &.stateFalse {
            color: $medium_grey;
          }
          @media (max-width: $breakpoint-md) {
            width: calc(100% - 48px);
            font-size: 14px;
          }
        }
      }
    }
  }
}
