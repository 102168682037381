@import '../breakpoints.scss';
@import '../variables.scss';

.section_partners_carousel {
  margin-top: 8.333vw;
  margin-bottom: 8.333vw;
  @media (max-width: $breakpoint-md) {
    margin: 64px 16px 64px 16px;
    overflow: hidden;
  }
  .container_partners {
    background-color: $white;
    width: 71.667vw;
    margin: auto;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 2.222vw;
    padding: 5.556vw 0;
    padding-bottom: 11.112vw;
    text-align: center;
    position: relative;
    opacity: 0;
    transition: opacity 0.8s ease;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      border-radius: 24px;
      padding: 64px 0;
      padding-bottom: 128px;
    }
    &.visible {
      opacity: 1;
      .partners_subtitle {
        transform: translateY(0);
      }
      .partners_title {
        h1,
        h2 {
          transform: translateY(0);
        }
      }
      .item_partner {
        transform: scale(1);
      }
    }
    .partners_subtitle {
      font-weight: 400;
      font-size: 0.833vw;
      line-height: 120%;
      color: $fermata_red;
      margin-bottom: 1.111vw;
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        margin-bottom: 16px;
      }
    }
    .partners_title {
      h1,
      h2 {
        font-weight: 700;
        font-size: 2.222vw;
        line-height: 120%;
        margin-bottom: 2.222vw;
        color: $dark_grey;
        font-family: 'TWKLausanne-700';
        transform: translateY(20px);
        transition: transform 0.8s ease;
        @media (max-width: $breakpoint-md) {
          font-size: 24px;
          margin-bottom: 32px;
        }
      }
    }
    .item_partner {
      width: 10.556vw;
      height: 10.556vw;
      display: flex;
      margin: 0 0.833vw;
      align-items: center;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        width: 58px;
        height: auto;
        margin: 0 8px;
      }
      img {
        width: 100%;
      }
    }
    .partners_arrows {
      width: 22.778vw;
      position: absolute;
      bottom: 7vw;
      display: flex;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-between;
      z-index: 2;
      @media (max-width: $breakpoint-md) {
        width: 85.7%;
      }
      span {
        width: 1.667vw;
        height: 1.667vw;
        display: block;
        position: relative;
        z-index: 4;
        cursor: pointer;
        @media (max-width: $breakpoint-md) {
          width: 24px;
          height: 24px;
        }
        img {
          width: 100%;
          &.prev_arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
