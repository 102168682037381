@import '../variables.scss';
@import '../typography.scss';

.contactFormWrapper {
  position: relative;
  max-width: 35.069vw;
  @media (max-width: $breakpoint-md) {
    max-width: 100%;
    padding: 0 16px;
  }
  .form_title {
    * {
      font-size: 1.667vw;
      font-family: 'TWKLausanne-700';
      line-height: 120%;
      color: $dark_grey;
      margin-bottom: 1.111vw;
      margin-top: 5.556vw;
      @media (max-width: $breakpoint-md) {
        margin-top: 64px;
        font-size: 24px;
        margin-bottom: 16px;
      }
    }
  }
  .form_description {
    * {
      font-size: 0.972vw;
      color: $medium_grey;
      padding-bottom: 1.667vw;
      line-height: 150%;
      font-family: 'TWKLausanne-300';
      border-bottom: 0.035vw solid rgba(25, 27, 37, 0.3);
      @media (max-width: $breakpoint-md) {
        font-size: 14px;
        padding-bottom: 32px;
        border-bottom: 0.5px solid rgba(25, 27, 37, 0.3);
      }
    }
  }
  form {
    padding: 1.667vw 0 8.333vw 0;
    @media (max-width: $breakpoint-md) {
      padding: 32px 0 68px 0;
    }
  }
  .formRow {
    display: flex;
    flex-direction: space-between;
    margin-bottom: 1.667vw;
    gap: 1.667vw;
    @media (max-width: $breakpoint-md) {
      gap: 24px;
      flex-direction: column;
      margin-bottom: 24px;
    }
    .formItem {
      width: 50%;
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
      &.full {
        width: 100%;
      }
      label,
      .select_label {
        display: block;
        font-size: 0.972vw;
        margin-bottom: 0.556vw;
        color: $dark_grey;
        font-family: 'TWKLausanne-300';
        line-height: 150%;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
      .select_label {
        @media (max-width: $breakpoint-md) {
          margin-top: 24px;
        }
      }
      input,
      textarea,
      select {
        padding: 0.833vw 1.111vw;
        font-size: 0.972vw;
        line-height: 150%;
        background: $light_grey;
        border: 0;
        box-shadow: inset 2px 0px 8px 1.5px rgba(31, 25, 101, 0.04);
        border-radius: 0.278vw;
        width: 100%;
        color: $medium_grey;
        @media (max-width: $breakpoint-md) {
          padding: 12px 16px;
          border-radius: 4px;
          font-size: 14px;
          min-height: 45px;
        }
        &:focus-visible {
          border: none;
          outline: none;
        }
      }
      select {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url('/select-arrow.svg');
        background-size: 1.111vw;
        background-position: 95% center;
        background-repeat: no-repeat;
        @media (max-width: $breakpoint-md) {
          background-size: 16px;
          background-position: 95% center;
        }
      }
      option:not(:first-of-type) {
        color: $dark_grey;
      }
      textarea {
        resize: none;
      }
    }
    .submit_container {
      background: $red_orange_75_gradient;
      padding-right: 2.222vw;
      border-radius: 6.944vw;
      width: max-content;
      @media (max-width: $breakpoint-md) {
        padding-right: 0;
        border-radius: 100px;
        margin: auto;
      }
      .submit_btn {
        background-image: url('/mixed-arrow.svg');
        padding: 1.111vw 2.222vw;
        background-repeat: no-repeat;
        border: 0;
        background-position: right center;
        background-color: transparent;
        cursor: pointer;
        color: $white;
        font-family: 'TWKLausanne-450';
        font-size: 1.25vw;
        background-size: 1.806vw 1.111vw;
        @media (max-width: $breakpoint-md) {
          background-size: 26px 16px;
          padding: 16px 24px;
          font-size: 14px;
          padding-right: 60px;
          background-position: 80px center;
        }
      }
    }
  }
}
