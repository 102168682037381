@import '../breakpoints.scss';
@import '../variables.scss';

.partnersLogosContainer {
  padding: 50px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #eeeaee;

  .partners_title {

    h1,
    h2 {
      font-weight: 800;
      font-size: 3vw;
      line-height: 120%;
      margin-bottom: 4vw;
      color: $dark_grey;
      font-family: 'TWKLausanne-700';
      transform: translateY(20px);
      transition: transform 0.8s ease;

      @media (max-width: $breakpoint-xl) {
        font-size: 4vw;
      }

      @media (max-width: $breakpoint-md) {
        font-size: 30px;
        margin-bottom: 32px;
      }
    }
  }

  .logoGridContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 1000px;

    @media (max-width: $breakpoint-xl) {
      flex-direction: row;
    }

    .logoGrid {
      display: flex;
      justify-content: center;
      gap: 30px;
      flex-wrap: nowrap;
      width: 100%;

      @media (max-width: $breakpoint-md) {
        flex-direction: column;
      }

      @media (max-width: $breakpoint-xl) {
        flex-direction: column;
        justify-content: start;
      }

      .logoItem {
        position: relative;
        width: 100%;
        max-width: 150px;
        aspect-ratio: 16/9;
        /* 1:1 Aspect Ratio */
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
      }
    }
  }

}