@import '../variables.scss';

.navigationMenuWrapper {
  @media (max-width: $breakpoint-md) {
    width: 100%;
  }
  .menu_list {
    display: flex;
    width: 44.236vw;
    justify-content: space-between;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      flex-direction: column;
      order: 2;
      padding: 0 64px 48px;
    }
    > li {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 32px;
      }
      &.hasSubmenu {
        @media (max-width: $breakpoint-md) {
          border-bottom: 2px solid #f9f7f9;
          padding-bottom: 8px;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          height: 2.6vw;
          width: 9vw;
        }
        > a,
        > span {
          position: relative;
          padding-right: 1.667vw;
          display: inline-flex;
          align-items: center;
          @media (max-width: $breakpoint-md) {
            display: flex;
            justify-content: space-between;
          }

          &::before {
            content: '';
            background-image: url('/arrow-down.svg');
            background-repeat: no-repeat;
            display: block;
            width: 1.111vw;
            height: 1.111vw;
            position: absolute;
            right: 0;
            background-size: cover;
            @media (max-width: $breakpoint-md) {
              width: 16px;
              height: 16px;
            }
          }
        }
        &.subMenuVisible {
          .sub_menu_container_big {
            max-height: 17.361vw;
            @media (max-width: $breakpoint-md) {
              max-height: 320px;
            }
          }
          > a,
          > span {
            &::after {
              content: '';
              background: linear-gradient(90deg, #e80c26 0%, #ff4c00 100%);
              height: 0.208vw;
              width: 100%;
              display: block;
              border-radius: 0px 0px 2.222vw 2.222vw;
              position: absolute;
              bottom: -2.73vw;
              z-index: 3;
              @media (max-width: $breakpoint-md) {
                display: none;
              }
            }
            &::before {
              transform: rotate(180deg);
            }
          }
        }
      }
      > a,
      > span {
        font-size: 0.972vw;
        line-height: 150%;
        cursor: pointer;
        @media (max-width: $breakpoint-md) {
          font-size: 18px;
        }
        &:hover {
          color: $fermata_red;
        }
      }
      &.active {
        color: $fermata_red;
        position: relative;
        &::after {
          content: ' ';
          position: absolute;
          bottom: -2.813vw;
          left: 0;
          width: 100%;
          height: 0.208vw;
          background: $red_orange_50_gradient;
          border-radius: 0 0 2.222vw 2.222vw;
          @media (max-width: $breakpoint-md) {
            display: none;
          }
        }
      }
    }
  }
}
