@import '../breakpoints.scss';
@import '../typography.scss';
@import '../variables.scss';

.testimonialsWrapper {
  position: relative;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.8s ease;
  &.visible {
    opacity: 1;
    .testimonialsContainer .testimonialsSubtitle {
      transform: translateY(0);
    }
    .testimonialsContainer .testimonialsTitle {
      transform: translateY(0);
    }
    .testimonialsContainer .testimonialCarousel :global(.slick-slide) {
      transform: scale(1);
    }
    .testimonialsContainer .testimonialCarousel :global(.slick-dots) {
      transform: translateY(0);
      @media (max-width: $breakpoint-md) {
        transform: translate(-50%);
      }
    }
    .testimonialsContainer .partners_arrows {
      transform: translateY(0);
    }
  }
  .testimonialsContainer {
    max-width: 71.667vw;
    padding: 5.556vw 0 5.903vw 6.111vw;
    margin: auto;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 2.222vw;
    position: relative;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      padding: 64px 16px;
      border-radius: 32px;
      overflow: hidden;
    }
    .testimonialsSubtitle {
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        padding: 0 16px;
      }
      * {
        font-size: 0.833vw;
        color: $fermata_red;
        line-height: 120%;
        font-family: 'TWKLausanne-450';
        margin-bottom: 1.111vw;
        text-transform: uppercase;
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          margin-bottom: 8px;
        }
      }
    }
    .testimonialsTitle {
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        padding: 0 16px;
      }
      * {
        font-size: 2.222vw;
        line-height: 120%;
        color: $dark_grey;
        font-family: 'TWKLausanne-700';
        margin-bottom: 2.222vw;
        @media (max-width: $breakpoint-md) {
          font-size: 24px;
          margin-bottom: 32px;
        }
      }
    }
    .testimonialCarousel {
      @media (max-width: $breakpoint-md) {
        padding: 0 0 0 24px;
      }
      :global(.slick-arrow) {
        display: none;
        width: 0;
        height: 0;
      }
      :global(.slick-track) {
        display: flex;
        gap: 1.667vw;
        @media (max-width: $breakpoint-md) {
          gap: 24px;
        }
      }
      :global(.slick-slide) {
        width: 47.222vw;
        height: inherit;
        border-radius: 2.222vw;
        display: flex;
        align-items: center;
        transform: scale(0.8);
        transform-origin: center center;
        transition: transform 0.8s ease;
        @media (max-width: $breakpoint-md) {
          min-width: calc(100vw - 80px);
          width: 300px;
          border-radius: 12px;
        }
        video {
          width: 100%;
          height: 26.389vw;
          border-radius: 2.222vw;
          @media (max-width: $breakpoint-md) {
            height: 165px;
            border-radius: 12px;
          }
        }
        > div {
          height: 100%;
          width: 100%;
        }
      }
      .testimonialItem {
        height: 100%;
        min-height: 26.4vw;
        border-radius: 2.222vw;
        position: relative;
        @media (max-width: $breakpoint-md) {
          border-radius: 12px;
          min-height: 250px;
        }
        &.textArea {
          padding: 0 6.111vw;
          background-color: $violet_02;
          @media (max-width: $breakpoint-md) {
            padding: 0 16px;
            max-height: 165px;
            overflow: scroll;
          }
          .wrapperQuotes {
            display: flex;
            height: 100%;
            justify-content: center;
            flex-direction: column;
            padding: 20px 0;
            @media (max-width: $breakpoint-md) {
              height: auto;
            }
          }
        }
        &.vimeoClass {
          width: 47.222vw;
          @media (max-width: $breakpoint-md) {
            width: 300px;
          }
          div:not(.imagePlay):not(.imagePlayWrapper) {
            width: 100%;
            height: 100%;
            iframe {
              width: 100%;
              height: 100%;
              border-radius: 2.222vw;
              @media (max-width: $breakpoint-md) {
                border-radius: 12px;
              }
            }
          }
        }
        .cardTitle {
          font-size: 1.667vw;
          color: $white;
          margin-bottom: 2.222vw;
          font-family: 'TWKLausanne-450';
          line-height: 120%;
          @media (max-width: $breakpoint-md) {
            padding-top: 16px;
            font-size: 16px;
            margin-bottom: 32px;
          }
        }
        .author {
          color: $white;
          font-size: 0.833vw;
          line-height: 120%;
          font-family: 'TWKLausanne-450';
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            padding-bottom: 16px;
          }
        }
      }

      :global(.slick-dots) {
        width: 22.778vw;
        bottom: -3.6vw;
        z-index: 2;
        left: 12.222vw;
        transform: translateY(20px);
        transition: transform 0.8s ease;
        @media (max-width: $breakpoint-md) {
          left: 50%;
          transform: translate(-20%);
          width: max-content;
          bottom: -40px;
          margin-left: -10px;
        }
        li {
          width: 1.111vw;
          height: 0.556vw;
          margin: 0.278vw;
          @media (max-width: $breakpoint-md) {
            width: 16px;
            height: 8px;
            margin: 8px;
          }
          button {
            width: 100%;
            height: 100%;
            padding: 0;
            border-radius: 0.833vw;
            background-color: $hover_grey;
            @media (max-width: $breakpoint-md) {
              border-radius: 12px;
            }
            &::before {
              display: none;
            }
          }
        }
        :global(.slick-active) {
          width: 2.222vw;
          @media (max-width: $breakpoint-md) {
            width: 32px;
          }
          button {
            background-color: $discharging;
          }
        }
      }
      .imagePlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        cursor: pointer;
        .imagePlayWrapper {
          background: $red_orange_50_gradient;
          border-radius: 2.222vw;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          position: absolute;
          @media (max-width: $breakpoint-md) {
            width: 48px;
            border-radius: 32px;
            height: 48px;
            position: absolute;
          }
        }
        img {
          width: 2.222vw;
          height: 2.222vw;
          margin: 1.111vw;
          @media (max-width: $breakpoint-md) {
            width: 24px;
            height: 24px;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
        }
        &.inactive {
          display: none;
        }
        @media (max-width: $breakpoint-md) {
          width: 100%;
          height: 100%;
          border-radius: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .partners_arrows {
      width: 22.778vw;
      position: absolute;
      bottom: 2vw;
      display: flex;
      left: 18.333vw;
      justify-content: space-between;
      z-index: 2;
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        left: 40px;
        right: 40px;
        width: auto;
        bottom: 25px;
      }
      span {
        width: 1.667vw;
        height: 1.667vw;
        display: block;
        position: relative;
        z-index: 4;
        cursor: pointer;
        @media (max-width: $breakpoint-md) {
          width: 24px;
          height: 24px;
        }
        img {
          width: 100%;
          &.prev_arrow {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}
