@import '../variables.scss';

.filtersWrapper {
  position: relative;
  width: fit-content;
  margin: 4.444vw auto;
  opacity: 0;
  transition: all 0.8s ease;
  transform: scale(0.8);
  transform-origin: center center;
  @media (max-width: $breakpoint-md) {
    width: 100%;
    padding: 48px 24px;
    margin: 0;
    background: $white;
  }
  &.visible {
    opacity: 1;
    transform: scale(1);
  }
  ul {
    display: flex;
    justify-content: center;
    background: $hover_grey;
    border-radius: 2.222vw;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.111vw 0.611vw;
      margin: 0.278vw;
      width: 11.333vw;
      text-align: center;
      text-transform: uppercase;
      color: $medium_grey;
      font-family: 'TWKLausanne-450';
      font-size: 0.833vw;
      line-height: 1vw;
      &.active {
        background: $white;
        border-radius: 2.022vw;
        color: $fermata_red;
        font-family: 'TWKLausanne-700';
      }
    }
  }
  .filterSelect {
    background: $hover_grey;
    border-radius: 4px;
    font-family: 'TWKLausanne-450';
    color: $medium_grey;
  }

  .filterSelect {
    ::first-letter {
      text-transform: capitalize;
    }
    :last-child {
      :last-child {
        border-bottom: 0;
        border-radius: 0 0 4px 4px;
      }
    }
  }

  p {
    font-family: 'TWKLausanne-300';
    font-size: 14px;
    line-height: 21px;
    color: $dark_grey;
    margin-bottom: 8px;
  }
}

@keyframes fadeIn {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 350px;
  }
}

.menu {
  animation: fadeIn 0.3s ease-out;
  overflow: scroll;
  scrollbar-width: none; /* removes scrollbar for Firefox */
  -ms-overflow-style: none; /* removes scrollbar for IE and Edge */
}
.menu::-webkit-scrollbar {
  display: none; /* removes scrollbar for Chrome, Safari, Opera */
}
