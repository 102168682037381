@import '../variables.scss';

.featuresCarouselWrapper {
  position: relative;
  width: 71.667vw;
  margin: 8.333vw auto;
  padding: 4.444vw 0 8.333vw 0;
  background: $red_orange_50_gradient;
  border-radius: 2.222vw 2.222vw 2.222vw 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    width: 92%;
    margin: 64px auto;
    padding: 64px 0 120px 0;
    border-radius: 0;
  }
  &.visible {
    opacity: 1;
    .featuresSubtitle > p {
      transform: translateY(0);
    }
    .featuresTitle > p {
      transform: translateY(0);
    }
    .carouselWrapper .carouselItem {
      transform: scale(1);
    }
    .features_arrows {
      transform: translateY(0);
    }
    .carouselWrapper :global(.slick-dots) {
      transform: translateY(0);
    }
  }
  .featuresSubtitle > p {
    font-family: 'TWKLausanne-450';
    text-transform: uppercase;
    font-size: 0.833vw;
    line-height: 0.972vw;
    letter-spacing: 0.07em;
    color: $white;
    margin-bottom: 1.111vw;
    padding-left: 6.111vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      line-height: 14.4px;
      margin-bottom: 8px;
      padding-left: 16px;
    }
  }
  .featuresTitle > p {
    font-family: 'TWKLausanne-700';
    font-size: 2.222vw;
    line-height: 2.639vw;
    color: $white;
    margin-bottom: 3.333vw;
    padding-left: 6.111vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      font-size: 24px;
      line-height: 28.8px;
      margin-bottom: 32px;
      padding-left: 16px;
    }
  }
  .carouselWrapper {
    display: flex;
    flex-flow: row nowrap;
    overflow: hidden;
    padding-left: 6.111vw;
    @media (max-width: $breakpoint-md) {
      padding-left: 16px;
    }
    .carouselItem {
      width: 16.667vw;
      background: $white;
      padding: 1.667vw;
      border-radius: 1.667vw;
      margin-right: 1.667vw;
      height: 100%;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        width: 237px;
        padding: 16px;
        border-radius: 24px;
        margin-right: 16px;
      }
      .itemTitle {
        font-family: 'TWKLausanne-700';
        font-size: 1.25vw;
        line-height: 1.875vw;
        color: $fermata_red;
        margin-bottom: 0.694vw;
        @media (max-width: $breakpoint-md) {
          font-size: 18px;
          line-height: 27px;
          margin-bottom: 10px;
        }
      }
      .itemDescription {
        font-family: 'TWKLausanne-300';
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: $medium_grey;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
        }
      }
    }
    :global(.slick-dots) {
      transform: translateY(20px);
      transition: transform 0.8s ease;
    }
  }
  .features_arrows {
    display: flex;
    justify-content: space-between;
    width: 22.778vw;
    margin: auto;
    position: absolute;
    bottom: 4.63vw;
    left: 0;
    right: 0;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      width: calc(100% - 32px);
      bottom: 72px;
    }
    span {
      width: 1.667vw;
      height: 1.667vw;
      display: block;
      position: relative;
      z-index: 4;
      cursor: pointer;
      @media (max-width: $breakpoint-md) {
        width: 24px;
        height: 24px;
      }
      img {
        width: 100%;
        &.prev_arrow {
          transform: rotate(180deg);
        }
      }
    }
  }
}
