@import '../variables.scss';
@import '../breakpoints.scss';
@import '../typography.scss';

.wysiwygWrapper {
  position: relative;
  padding-top: 2.33vw;
  padding-bottom: 2.333vw;
  background: $white;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  img {
    max-width: 100%;
  }
  &.visible {
    opacity: 1;
    .wysiwygContainer {
      transform: translateY(0);
    }
    .wysiwygContainer .wysiwygTextarea img {
      transform: scale(1);
    }
  }
  .wysiwygContainer {
    max-width: 59.444vw;
    margin: auto;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      margin: 0 16px;
    }
    .wysiwygSubtitle {
      margin-bottom: 1.111vw;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 8px;
      }
      * {
        font-size: 0.833vw;
        line-height: 120%;
        font-family: 'TWKLausanne-450';
        color: $fermata_red;
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
        }
      }
    }
    .wysiwygTitle {
      margin-bottom: 2.222vw;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 32px;
      }
      * {
        font-family: 'TWKLausanne-300';
        font-size: 3.333vw;
        line-height: 120%;
        color: $dark_grey;
        @media (max-width: $breakpoint-md) {
          font-size: 32px;
        }
        strong {
          font-family: 'TWKLausanne-700';
        }
      }
    }
    .wysiwygTextarea {
      font-size: 1.25vw;
      color: $medium_grey;
      line-height: 150%;
      @media (max-width: $breakpoint-md) {
        font-size: 18px;
      }
      p {
        margin-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 16px;
        }
      }
      p:last-child {
        margin-bottom: 0px;
      }
      img {
        width: 100%;
        transform: scale(0.8);
        transform-origin: center center;
        transition: transform 0.8s ease;
      }
      a {
        color: $fermata_violet;
        text-decoration: underline;
      }
      ul {
        margin: 0;
        padding-left: 1.5rem;
        li {
          list-style-type: disc;
          list-style-position: outside;
        }
      }
    }
    .wysiwygTextarea > div {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
    }
    .wysiwygTextarea > div > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
