@import '../variables.scss';

.customerStoriesWrapper {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: $dark_grey;
  padding: 8.333vw 14.167vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    padding: 64px 0 64px 0;
  }
  &.visible {
    opacity: 1;
    .leftSideContent {
      transform: translateY(0);
    }
  }
  .leftSideContent {
    width: 22.778vw;
    margin-right: 1.667vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      padding: 0 16px;
      margin-right: 0;
    }
    .subtitle > p {
      font-family: 'TWKLausanne-450';
      text-transform: uppercase;
      color: $light_grey_70;
      font-size: 0.833vw;
      line-height: 1wv;
      margin-bottom: 1.111vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
        margin-bottom: 16px;
      }
    }
    .title > p {
      font-family: 'TWKLausanne-700';
      color: $white;
      font-size: 2.222vw;
      line-height: 2.667vw;
      margin-bottom: 2.222vw;
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 32px;
      }
    }
    .btn {
      display: flex;
      align-items: center;
      img {
        margin-left: 0.833vw;
      }
    }
  }
  .articleCardWrapper {
    display: flex;
    flex-flow: row nowrap;
    gap: 1.667vw;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      overflow-x: scroll;
      gap: 0;
      scrollbar-width: none;
      -ms-overflow-style: none;
      padding-left: 16px;
      scroll-snap-type: x mandatory;
    }
  }
  .articleCardWrapper::-webkit-scrollbar {
    display: none;
  }
  .btnWrapper {
    align-self: flex-start;
    padding-left: 16px;
    margin-top: 32px;
    button {
      display: flex;
      align-items: center;
      img {
        margin-left: 12px;
        vertical-align: middle;
      }
    }
  }
}
