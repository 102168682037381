@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.articleCardWrapper {
  cursor: pointer;
  position: relative;
  transform: scale(0.8);
  transform-origin: center center;
  transition: transform 0.8s ease;
  width: 100%;
  &.gridVariant {
    @media (max-width: $breakpoint-xl) {
      width: auto;
    }
  }
  &.slideVariant {
    @media (max-width: $breakpoint-xl) {
      min-width: 350px;
    }
  }
  &.visible {
    transform: scale(1);
    margin-bottom: 20px;
  }
  .category_position {
    position: absolute;
    top: 1.111vw;
    left: 1.111vw;
    background: $light_grey;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 6.944vw;
    text-transform: uppercase;
    padding: 0.278vw 1.111vw;
    color: $discharging;
    font-size: 0.833vw;
    line-height: 120%;
    font-family: 'TWKLausanne-700';
    z-index: 2;
    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      border-radius: 100px;
      padding: 4px 16px;
      top: 16px;
      left: 16px;
    }
  }
  .image_wrapper {
    width: inherit;
    height: 16.667vw;
    position: relative;
    overflow: hidden;
    border-radius: 0.833vw 0.833vw 0.833vw 0px;
    @media (max-width: $breakpoint-xl) {
      height: 19.667vw;
    }
    @media (max-width: $breakpoint-md) {
      width: auto;
      height: 255px;
      border-radius: 12px 12px 12px 0px;
    }
  }
  .news_image_wrapper {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    background-color: #f0f0f0;
    border-radius: 0.833vw 0.833vw 0.833vw 0px;
    /* @media (max-width: $breakpoint-xl) {
      height: 19.667vw;
    }
    @media (max-width: $breakpoint-md) {
      width: auto;
      height: 255px;
      border-radius: 12px 12px 12px 0px;
    } */
  }
  .article_body_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    height: 230px;
    background-color: #ffffff;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 0.833vw;
    margin: -1.00vw 1.111vw 0 1.111vw;
    position: relative;
    @media (max-width: $breakpoint-xl) {
      margin: -18px 16px 0 16px;
      border-radius: 12px;
    }

    @media (max-width: $breakpoint-md) {
      margin: -18px 16px 0 16px;
      border-radius: 12px;

      &.gridBodyVariation {
        height: fit-content;
      }
      &.slideBodyVariantion {
        height: 180px;
      }
    }
    .article_body_wrapper_text_section {
      height: fit-content;
    }
    .cardTitle {
      font-family: 'TWKLausanne-700';
      color: $dark_grey;
      font-size: 16px;
      line-height: 22.4px;
      margin-bottom: 5px;
      min-height: 40px;
    }
    .cardDescription {
      font-family: 'TWKLausanne-300';
      color: $medium_grey;
      font-size: 16px;
      line-height: 17.78px;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 15px;
      }
    }
    .truncateHeadline {
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .truncateSubheadline {
      display: -webkit-box;
      line-clamp: 6;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .btn {
      display: flex;
      align-items: center;
      font-family: 'TWKLausanne-700';
      font-size: 16px;
      line-height: 17.78px;
      padding: 0;
      color: $fermata_red;
    }
    .dwldBtn::before {
      background-image: url('/download-header-red.svg');
      content: ' ';
      width: 1.389vw;
      height: 1.389vw;
      background-size: cover;
      margin: 0 0.833vw -0.23vw 0;
      display: inline-block;
      @media (max-width: $breakpoint-md) {
        width: 20px;
        height: 20px;
        margin: 0 10px -2px 0;
      }
    }
    .articleBtn::after {
      background-image: url('/mixed-arrow-red.svg');
      content: ' ';
      width: 0.9vw;
      height: 0.5vw;
      background-size: cover;
      margin-left: 0.5vw;
      display: inline-block;
      @media (max-width: $breakpoint-md) {
        width: 24px;
        height: 14px;
        margin-left: 10px;
      }
    }
  }
}
