@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.mixedWrapper {
  opacity: 0;
  transition: opacity 0.8s ease;
  &.visible {
    opacity: 1;
    .mixedContent_wrapper .mixedContent_container .top_section {
      transform: translateY(0);
    }
    .mixedContent_wrapper .mixedContent_container .cards_container {
      div {
        transform: scale(1);
      }
    }
  }
}
.mixedContent_wrapper {
  padding-bottom: 8.333vw;
  padding-top: 8.333vw;
  @media (max-width: $breakpoint-md) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
  .mixedContent_container {
    width: 59.444vw;
    margin: auto;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      padding: 0 16px;
    }
    .top_section {
      max-width: 35vw;
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        max-width: 100%;
      }
      .top_subtitle p {
        font-weight: 400;
        font-size: 0.833vw;
        line-height: 120%;
        color: $fermata_red;
        margin-bottom: 1.111vw;
        text-transform: uppercase;
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          margin-bottom: 8px;
        }
      }
      h1,
      h2 {
        font-weight: 700;
        font-size: 3.333vw;
        line-height: 120%;
        color: $dark_grey;
        margin-bottom: 2.222vw;
        strong {
          font-family: 'TWKLausanne-700';
        }
        @media (max-width: $breakpoint-md) {
          font-size: 32px;
          margin-bottom: 24px;
        }
      }
      p {
        font-weight: 300;
        font-size: 0.972vw;
        line-height: 150%;
        color: $medium_grey;
        margin-bottom: 2.222vw;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          margin-bottom: 48px;
        }
      }
    }
    .cards_container {
      display: flex;
      gap: 1.667vw;
      filter: drop-shadow(0px 48px 48px rgba(14, 11, 53, 0.04));
      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        gap: 24px;
      }
    }
    .cards_container {
      div {
        transform: scale(0.8);
        transform-origin: center center;
        transition: transform 0.8s ease;
      }
    }
  }
}
