@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.statsCardsWrapper {
  position: relative;
  padding-bottom: 8.333vw;
  background: $white;
  .big_container_wrapper {
    width: 71.667vw;
    margin: auto;
    position: relative;
  }
  @media (max-width: $breakpoint-md) {
    width: 100%;
    padding: 0 16px;
    padding-bottom: 64px;
  }
  .stats_title_wrapper {
    width: 71.667vw;
    margin: auto;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      padding: 0 16px;
    }
    * {
      font-size: 2.222vw;
      color: $dark_grey;
      line-height: 120%;
      margin-bottom: 3.333vw;
      font-family: 'TWKLausanne-700';
      text-align: center;
      @media (max-width: $breakpoint-md) {
        font-size: 24px;
        margin-bottom: 48px;
      }
    }
  }
  .cards_container {
    display: flex;
    gap: 1.667vw;
    justify-content: center;
    filter: drop-shadow(0px 48px 48px rgba(14, 11, 53, 0.04));
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      gap: 24px;
    }
  }
}
