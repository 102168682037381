@import '../breakpoints.scss';
@import '../typography.scss';
@import '../variables.scss';

.expandableProfileWrapper {
  position: relative;
  padding: 8.33vw 16px;
  opacity: 0;
  transform: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    padding: 64px 16px;
  }
  &.visible {
    opacity: 1;
    .container_wrapper .subtitle {
      transform: translateY(0);
    }
    .container_wrapper .title {
      transform: translateY(0);
    }
  }
  .container_wrapper {
    max-width: 59.444vw;
    margin: auto;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
    }
    .subtitle {
      font-size: 0.833vw;
      text-align: center;
      color: $fermata_red;
      font-family: 'TWKLausanne-450';
      line-height: 120%;
      margin-bottom: 1.111vw;
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        margin-bottom: 8px;
      }
    }
    .title {
      font-size: 3.333vw;
      color: $dark_grey;
      line-height: 120%;
      text-align: center;
      margin-bottom: 3.333vw;
      font-family: 'TWKLausanne-300';
      transform: translateY(20px);
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
        margin-bottom: 32px;
      }
      strong,
      b {
        font-family: 'TWKLausanne-700';
      }
    }
  }
}
