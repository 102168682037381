@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.profileCategoryWrapper {
  position: relative;
  .sliceWrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @media (max-width: $breakpoint-md) {
      margin-top: 32px;
      flex-direction: column;
    }

    &.fourCols {
      justify-content: space-between;
    }
  }
  .container_detail {
    margin-top: 2.22vw;
    background: $white;
    z-index: 2;
    position: relative;
    padding: 5.556vw 6.111vw 3.472vw 6.111vw;
    box-shadow: 0px 48px 48px rgba(14, 11, 53, 0.04);
    border-radius: 2.222vw 0px 2.222vw 2.222vw;
    @media (max-width: $breakpoint-md) {
      border-radius: 32px 0px 32px 32px;
      padding: 24px;
    }
    .close_trigger {
      width: 1.667vw;
      height: 1.667vw;
      position: absolute;
      right: 1.667vw;
      top: 1.667vw;
      cursor: pointer;
      @media (max-width: $breakpoint-md) {
        width: 24px;
        height: 24px;
        top: 24px;
        right: 24px;
      }
    }
    .box_header {
      display: flex;
      gap: 1.667vw;
      margin-bottom: 2.222vw;
      @media (max-width: $breakpoint-md) {
        gap: 24px;
        margin-bottom: 32px;
      }
      span {
        width: 4.444vw;
        height: 4.444vw;
        @media (max-width: $breakpoint-md) {
          width: 64px;
          height: 64px;
        }
      }
      .right_area {
        .jobtitle {
          color: $fermata_red;
          font-size: 0.833vw;
          margin-bottom: 1.111vw;
          line-height: 120%;
          font-family: 'TWKLausanne-450';
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            margin-bottom: 16px;
          }
        }
        .name {
          font-size: 1.667vw;
          color: $dark_grey;
          font-family: 'TWKLausanne-700';
          @media (max-width: $breakpoint-md) {
            font-size: 24px;
          }
        }
      }
    }
    .box_content {
      color: $medium_grey;
      font-size: 0.972vw;
      font-family: 'TWKLausanne-300';
      line-height: 150%;
      @media (max-width: $breakpoint-md) {
        font-size: 14px;
      }
      p {
        margin-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          margin-bottom: 16px;
        }
      }
      p:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
