@import '../breakpoints.scss';
@import '../variables.scss';

.heroSection {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -20px;
  background: linear-gradient($white 95%, $light-grey 95%, $light-grey 100%);
  @media (max-width: $breakpoint-md) {
    margin-bottom: 0;
  }
  &.reverse {
    flex-direction: row;
    .contentWrapper .content {
      margin-left: 1.66vw;
      max-width: 35vw;
      @media (max-width: $breakpoint-md) {
        margin-left: 0;
        max-width: none;
      }
    }
  }
  &.fullWidth {
    padding-bottom: 0px;
    .image {
      width: 100%;
      img {
        width: 100vw;
        @media (max-width: $breakpoint-md) {
          min-height: 600px; // height only needed for fullwidth hero
          object-fit: cover;
        }
      }
    }
    .contentWrapper {
      position: absolute;
      width: 71.66%;
      left: 50%;
      top: 33%;
      transform: translate(-50%, -50%);
      background: none;
      @media (max-width: $breakpoint-md) {
        width: 92%;
        transform: translate(-50%, -70%);
      }
      .content {
        width: 50%;
        margin-left: 0;
        @media (max-width: $breakpoint-md) {
          width: 100%;
        }
      }
    }
  }
  &.darkTheme {
    color: $dark_grey;
    .contentWrapper > .content > .title > p {
      color: $medium_grey;
    }
  }
  &.lightTheme {
    color: $white;
    .contentWrapper .content .title .subtitle > p {
      color: $white;
    }
  }

  @media (max-width: $breakpoint-md) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: flex-end;
    &.reverse {
      flex-direction: column-reverse;
    }
  }
  .image {
    width: 49.16%;
    height: auto;
    position: relative;
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      vertical-align: middle;
      @media (max-width: $breakpoint-md) {
        max-height: 210px; // changed for halfwidth hero
        object-fit: cover;
      }
    }

    .imagePlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;

      .imagePlayWrapper {
        background: $red_orange_50_gradient;
        background-size: 100%;
        border-radius: 2.222vw;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        transition: linear 0.3s;
        @media (max-width: $breakpoint-md) {
          width: 48px;
          border-radius: 32px;
          height: 48px;
          position: absolute;
        }

        @media (min-width: $breakpoint-md) {
          &:hover {
            border-radius: 2.4vw;
            background-size: 200%;
            box-shadow: 0px 4px 24px -16px rgba(167, 0, 20, 0.25);
            cursor: pointer;
            img {
              width: 2.4vw;
              height: 2.4vw;
            }
          }
        }

        img {
          width: 2.222vw;
          height: 2.222vw;
          margin: 1.111vw;
          transition: linear 0.3s;
          cursor: pointer;
          @media (max-width: $breakpoint-md) {
            width: 24px;
            height: 24px;
            margin: 0;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            position: absolute;
          }
        }
      }
      &.inactive {
        display: none;
      }
      @media (max-width: $breakpoint-md) {
        width: 100%;
        height: 100%;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .contentWrapper {
    width: 50.84%;
    background: $white;
    @media (max-width: $breakpoint-md) {
      width: 100%;
    }
    .content {
      margin-left: 14.16vw;
      max-width: 35vw;
      @media (max-width: $breakpoint-md) {
        max-width: none;
        margin-left: 0;
        padding: 64px 16px 24px;
      }
      .title {
        margin: 0;
        h1 {
          font-size: 4.02vw;
          margin: 1.111vw 0 2.222vw 0;
          line-height: 4.444vw;
          @media (max-width: $breakpoint-md) {
            font-size: 48px;
            line-height: 57.6px;
          }
          strong {
            font-family: 'TWKLausanne-700';
          }
        }
        p {
          font-size: 1.25vw;
          margin: 0;
          line-height: 1.875vw;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
          }
        }
        .subtitle > p {
          font-size: 0.833vw;
          text-transform: uppercase;
          line-height: 120%;
          font-family: 'TWKLausanne-450';
          letter-spacing: 7%;
          color: $fermata_red;
          @media (max-width: $breakpoint-md) {
            font-size: 12px;
            line-height: 14.4px;
          }
        }
      }
      .btn {
        margin-top: 2.222vw;
      }
      .btn:after {
        background-image: url('/mixed-arrow.svg');
        content: ' ';
        width: 1.9vw;
        height: 1.111vw;
        background-size: cover;
        margin-left: 0.833vw;
        display: inline-block;
        @media (max-width: $breakpoint-md) {
          width: 24px;
          height: 14px;
          margin-left: 12px;
        }
      }
      .secondary_white:hover::after {
        background-image: url('/mixed-arrow-red.svg');
      }
      .secondary_white:active::after {
        background-image: url('/mixed-arrow-active.svg');
      }
      .btn:active::after {
        background-size: contain;
        background-repeat: no-repeat;
        height: 0.972vw;
      }
    }
  }
  .featuresWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: relative;
    margin: 0 14.167vw;
    margin-top: -30px;
    width: 100%;
    text-align: center;
    background: $white;
    border-radius: 2.222vw;
    box-shadow: 0px 48px 48px 0px $box_shadow;
    @media (max-width: $breakpoint-md) {
      transform: translate(0, 0);
      order: -1;
      position: relative;
      margin: 25px 0 94px 16px;
      height: auto;
      width: calc(100% - 16px);
      overflow-x: scroll;
      border-radius: 24px 0 0 24px;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    .featuresContainer {
      display: flex;
      flex-flow: column;
      position: relative;
      padding: 1.667vw;
      @media (max-width: $breakpoint-md) {
        padding: 16px;
      }
      .featuresContent {
        display: flex;
        flex-flow: row;
        padding-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          padding-bottom: 8px;
        }
        .featureIcon {
          margin-right: 1.111vw;
          @media (max-width: $breakpoint-md) {
            margin-right: 8px;
          }
        }
        .featuresTitle {
          text-align: left;
          p:first-child {
            font-family: 'TWKLausanne-450';
            font-size: 0.833vw;
            line-height: 1vw;
            padding-bottom: 0.556vw;
            color: $dark_grey;
            @media (max-width: $breakpoint-md) {
              font-size: 12px;
              line-height: 14.4px;
              padding-bottom: 8px;
            }
          }
          p:nth-child(2) {
            font-family: 'TWKLausanne-700';
            font-size: 1.25vw;
            line-height: 1.875vw;
            color: $violet_02;
            @media (max-width: $breakpoint-md) {
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
      }
      .featuresDescription {
        width: 19.444vw;
        text-align: left;
        font-family: 'TWKLausanne-300';
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: $medium_grey;
        padding-bottom: 0;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
          min-width: 221px;
        }
        a {
          position: absolute;
          bottom: 1.528vw;
          right: 1.806vw;
          img {
            width: 1.667vw;
            height: 1.111vw;
            @media (max-width: $breakpoint-md) {
              width: 24px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .featuresWrapper::-webkit-scrollbar {
    display: none;
  }

  .featuresHide {
    display: none;
  }
}
