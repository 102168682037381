@import '../variables.scss';
@import '../typography.scss';

.section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8.333vw 20.486vw 8.333vw 20.486vw;
  background: $white;
  opacity: 0;
  transform: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    align-items: flex-start;
    padding: 64px 16px;
  }
  &.visible {
    opacity: 1;
    .titleWrapper {
      transform: translateY(0);
    }
    .textWrapper {
      transform: translateY(0);
    }
  }
  .titleWrapper {
    max-width: 22.778vw;
    margin-right: 1.667vw;
    align-self: flex-start;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      margin-right: 0;
    }
    p {
      text-transform: uppercase;
      font-family: 'TWKLausanne-450';
      font-size: 0.833vw;
      line-height: 0.972vw;
      color: $fermata_red;
      margin-bottom: 1.111vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 16px;
      }
    }
    h1 {
      font-family: 'TWKLausanne-700';
      font-size: 3.333vw;
      line-height: 4.028vw;
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 24px;
      }
    }
  }
  .textWrapper {
    max-width: 35vw;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
    }
    p {
      font-family: 'TWKLausanne-300';
      font-size: 1.25vw;
      line-height: 1.875vw;
      color: $medium_grey;
      @media (max-width: $breakpoint-md) {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
}
