@import '../breakpoints.scss';
@import '../variables.scss';
@import '../typography.scss';

.card_container {
  background: $white;
  width: 16.667vw;
  border-radius: 1.667vw;
  height: auto;
  padding: 2.222vw;
  position: relative;
  transform: scale(0.8);
  transform-origin: center center;
  transition: transform 0.8s ease;
  @media (max-width: $breakpoint-md) {
    width: 100%;
    //max-width: 345px;
    padding: 32px;
    min-height: 200px;
    border-radius: 24px;
  }
  &.visible {
    transform: scale(1);
  }
  .icon_wrapper {
    display: block;
    position: relative;
    width: 3.056vw;
    height: 3.056vw;
    span {
      min-height: 100%;
      min-width: 100%;
    }
    @media (max-width: $breakpoint-md) {
      width: 44px;
      height: 44px;
    }
  }
  .big_image {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 1.667vw;
      @media (max-width: $breakpoint-md) {
        border-radius: 24px;
      }
    }
  }
  &.card_big {
    background: $red_orange_75_gradient;
    border-radius: 1.667vw 1.667vw 1.667vw 0px;
    width: 22.778vw;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      border-radius: 24px 24px 24px 0px;
      margin-top: 104px;
      min-height: 304px;
      padding: 0;
    }
    .card_right_overlay {
      @media (max-width: $breakpoint-md) {
        position: relative;
        padding: 32px;
        height: 100%;
        max-width: 275px;
        margin: auto;
        min-height: 304px;
      }
      img {
        width: 90%;
        height: auto;
        position: absolute;
        top: -8vw;
        right: 50%;
        z-index: 2;
        transform: translateX(50%);
        pointer-events: none;

        @media (max-width: $breakpoint-md) {
          top: -111px;
        }
      }
      div {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: -18.7vw;
        left: 0;
        right: 0;
        @media (max-width: $breakpoint-md) {
          bottom: 32px;
        }
        a {
          display: flex;
          align-items: center;
        }
        .btn::after {
          background-image: url('/mixed-arrow.svg');
          content: ' ';
          width: 1.9vw;
          height: 1.2vw;
          background-size: cover;
          margin-left: 0.833vw;
          display: inline-block;
          @media (max-width: $breakpoint-md) {
            width: 24px;
            height: 14px;
            margin-left: 12px;
          }
        }
        .btn:hover::after {
          background-image: url('/mixed-arrow-red.svg');
        }

        .btn:active::after {
          background-image: url('/mixed-arrow-active.svg');
          background-size: contain;
          background-repeat: no-repeat;
          height: 0.972vw;
        }
      }
    }
    &.four_column {
      .card_right_overlay {
        img {
          top: -1vw;
          @media (max-width: $breakpoint-md) {
            top: -111px;
          }
        }
      }
    }
  }
  .icons_with_text {
    text-align: center;
    .icon_wrapper {
      margin: auto auto 1.111vw auto;
      @media (max-width: $breakpoint-md) {
        margin: auto auto 26px auto;
      }
    }
    .card_subtitle {
      margin-bottom: 0.556vw;
      font-weight: 700;
      font-size: 0.833vw;
      line-height: 120%;
      text-transform: uppercase;
      color: $dark_grey; // fixed typo
      font-family: 'TWKLausanne-700';
      @media (max-width: $breakpoint-md) {
        margin-bottom: 8px;
        font-size: 12px;
      }
    }
    .card_title {
      font-weight: 700;
      font-size: 3.333vw;
      line-height: 120%;
      margin-bottom: 1.111vw;
      color: $violet_02;
      font-family: 'TWKLausanne-700';
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
        margin-bottom: 16px;
      }
    }
    .card_description {
      font-weight: 300;
      font-size: 0.972vw;
      line-height: 150%;
      color: $medium_grey;
      @media (max-width: $breakpoint-md) {
        font-size: 14px;
      }
    }
  }
}
