@import '../breakpoints.scss';
@import '../typography.scss';
@import '../variables.scss';

.navigationSubmenuWrapper {
  position: absolute;
  overflow: hidden;
  background: $light_grey;
  width: 100%;
  left: 0;
  padding: 0 14.167vw;
  z-index: 2;
  top: 6.6vw;
  opacity: 0;
  transition: opacity 0.2s ease;
  @media (max-width: $breakpoint-md) {
    position: unset;
    padding: 0;
    background-color: $white;
  }
  > a {
    &::after {
      content: '';
      background: $red_orange_50_gradient;
      height: 0.208vw;
      width: 100%;
      display: block;
      border-radius: 0px 0px 2.222vw 2.222vw;
      position: absolute;
      bottom: -2.73vw;
      z-index: 3;
      @media (max-width: $breakpoint-md) {
        display: none;
      }
    }
    &::before {
      transform: rotate(180deg);
    }
  }
  .sub_menu_container_small {
    display: flex;
    padding: 2.222vw 0;
    @media (max-width: $breakpoint-md) {
      padding: 16px 0 32px 0;
      flex-direction: column;
    }
    .sub_menu_left {
      max-width: 22.778vw;
      margin-right: 7.778vw;
      @media (max-width: $breakpoint-md) {
        display: none;
      }
      h1,
      h2,
      h3 {
        font-size: 2.222vw;
        font-weight: 700;
        line-height: 120%;
        color: $dark_grey;
        margin-bottom: 0.556vw;
        font-family: 'TWKLausanne-700';
      }
      p {
        font-weight: 300;
        font-size: 0.972vw;
        line-height: 150%;
        color: $medium_grey;
      }
    }
    .sub_menu_center {
      width: 15.625vw;
      margin-right: 8.819vw;
      display: block;
      @media (max-width: $breakpoint-md) {
        width: 100%;
        margin-right: 0;
        padding-bottom: 16px;
      }
      a {
        padding: 1.181vw 0.833vw 1.181vw 2.639vw;
        font-size: 0.972vw;
        line-height: 100%;
        color: $dark_grey;
        margin-bottom: 0.278vw;
        display: inline-flex;
        align-items: center;
        position: relative;
        font-family: 'TWKLausanne-450';
        @media (max-width: $breakpoint-md) {
          padding: 17px 12px 17px 38px;
          font-size: 14px;
          margin-bottom: 5px;
        }
        &::before {
          content: '';
          background-image: url('/arrow-red.svg');
          width: 0.833vw;
          height: 0.833vw;
          background-size: cover;
          background-repeat: no-repeat;
          display: block;
          margin-right: 0.694vw;
          position: absolute;
          left: 1.111vw;
          @media (max-width: $breakpoint-md) {
            width: 11.5px;
            height: 11.5px;
            margin-right: 10px;
            left: 16px;
          }
        }
      }
    }
    .sub_menu_right {
      width: 16.667vw;
      background-image: url('/background-header.png');
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 0.833vw 0.833vw 0.833vw 0px;
      position: relative;
      @media (max-width: $breakpoint-md) {
        height: 48px;
        background: $light_grey;
        border: 2px solid $fermata_red;
        border-radius: 100px;
        width: max-content;
        align-items: center;
        display: flex;
        padding: 0 24px;
      }
      img {
        position: absolute;
        top: 1.667vw;
        width: 4.444vw;
        height: 5.139vw;
        left: 0;
        display: block;
        right: 0;
        margin: auto;
        @media (max-width: $breakpoint-md) {
          display: none;
        }
      }
      a {
        position: absolute;
        bottom: 1.736vw;
        left: 50%;
        transform: translateX(-50%);
        font-weight: 450;
        font-size: 0.972vw;
        line-height: 100%;
        color: $white;
        display: flex;
        width: max-content;
        align-items: center;
        font-family: 'TWKLausanne-450';
        @media (max-width: $breakpoint-md) {
          color: $fermata_red;
          font-weight: 450;
          font-size: 14px;
          position: unset;
          transform: translateX(0);
          left: 0;
          display: flex;
          flex-direction: row-reverse;
          gap: 8px;
        }
        &::before {
          content: '';
          background-image: url('/download-header.svg');
          width: 1.389vw;
          height: 1.389vw;
          display: block;
          background-size: cover;
          margin-right: 0.625vw;
          @media (max-width: $breakpoint-md) {
            width: 20px;
            height: 20px;
            background-image: url('/download-header-red.svg');
            margin: 0;
          }
        }
      }
    }
  }
}
