@import '../variables.scss';
@import '../breakpoints.scss';

.videoHero {
  height: 80vh;
  overflow: hidden;

  @media (max-width: $breakpoint-xl) {
    height: 100vh;
  }

  @media (max-width: $breakpoint-md) {
    height: 100vh;
  }
}

.mainSection {
  position: relative;
  display: block;
  width: 100%;

  &.longTitle {
    display: flex;
    align-items: center;

    @media (min-width: $breakpoint-md) {
      padding: 4vw 0 8vw;
      min-height: 24.306vw;
    }

    .imageWrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        object-fit: cover;
        max-height: none;
        height: 100%;

        @media (min-width: $breakpoint-md) {
          object-position: 90% 50%;
        }
      }

      &.biggerWrapper {
        img {
          min-height: 0;
          max-height: none;
        }
      }
    }

    .contentWrapper {
      position: relative;
      top: 0;
      transform: translateX(-50%);

      .textWrapper {

        .title>h1,
        .subtitle>p,
        .description>p {
          text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
        }

        @media (min-width: $breakpoint-md) {
          width: 80%;
        }
      }
    }
  }

  .videoContainer {
    padding: 56.25% 0 0 0;
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: $breakpoint-xl) {
      padding: 0;
      min-height: 100vh;
      overflow: hidden;
    }

    @media (max-width: $breakpoint-md) {
      padding: 90%;
      min-height: 500px;
      overflow: hidden;
    }

    .videoFrame {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;

      @media (max-width: $breakpoint-xxl) {
        transform: scale(1.2);
      }

      @media (max-width: $breakpoint-xl) {
        transform: scale(2);
      }

      @media (max-width: $breakpoint-md) {
        transform: scale(2.3);
      }
    }

    .videoPlaceholder {
      @extend .videoFrame;
      transform: scale(1);
    }
  }

  .imageWrapper {
    width: 100%;
    position: relative;

    img {
      min-height: 24.306vw;
      max-height: 457px;

      @media (max-width: $breakpoint-md) {
        min-height: 400px;
      }
    }

    &.biggerWrapper {
      img {
        min-height: 34.722vw;
        max-height: none;

        @media (max-width: $breakpoint-md) {
          min-height: 600px;
          max-height: none;
        }
      }
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
    width: 100%;
    padding: 0 30vh;

    @media (max-width: $breakpoint-xl) {
      padding: 0px 10vh;
      top: 50%;
    }

    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      padding: 64px 16px;
      top: 50%;
    }

    .textWrapper {
      width: 100%;

      @media (max-width: $breakpoint-md) {
        width: 100%;
      }

      .subtitle {
        display: block;
        font-family: 'TWKLausanne-700';
        font-size: 0.533vw;
        line-height: 0.972vw;
        text-transform: uppercase;
        margin-bottom: 5px;

        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          line-height: 14.4px;
          margin-bottom: 8px;
        }
      }

      .title {
        font-family: 'TWKLausanne-700';
        font-size: 2.444vw;
        line-height: 4.111vw;

        @media (max-width: $breakpoint-xl) {
          font-size: 3.444vw;
          line-height: 6.111vw;
        }

        @media (max-width: $breakpoint-md) {
          font-size: 28px;
          line-height: 48px;
        }

        strong {
          font-family: 'TWKLausanne-700';
        }
      }

      .description>p {
        font-family: 'TWKLausanne-300';
        font-size: 1.25vw;
        line-height: 1.5vw;
        color: $light_grey;
        margin-top: 1vw;
        width: 70%;

        @media (max-width: $breakpoint-xl) {
          font-size: 1.8vw;
          line-height: 2.5vw;
        }

        @media (max-width: $breakpoint-md) {
          width: 100%;
          font-size: 18px;
          line-height: 27px;
          margin-top: 32px;
        }
      }
    }

    .btnWrapper {
      display: flex;
      align-self: flex-start;
      gap: 20px;
      margin-top: 20px;

      @media (max-width: $breakpoint-md) {
        flex-direction: column;
        width: 100%;
      }

      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        height: 3em;

        @media (max-width: $breakpoint-xl) {
          font-size: 1.5vw;
          height: 4em;
        }

        @media (max-width: $breakpoint-md) {
          width: 100%;
          margin: auto;
          align-self: center;
          font-size: 3.6vw;
          height: 4em;
        }
      }

      .primaryBtn {
        background: $red_orange_50_gradient;
        color: $light_grey;
      }

      .primaryBtn::after {
        background-image: url('/arrow-right-white.svg');
        content: ' ';
        width: 1.667vw;
        height: 1.667vw;
        background-size: cover;
        margin-left: 0.694vw;
        display: inline-block;

        @media (max-width: $breakpoint-md) {
          width: 20px;
          height: 20px;
          margin-left: 6px;
        }
      }

      .secondaryBtn {
        color: $fermata_red;
      }
    }
  }
}

.heroSection {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: -20px;
  background: linear-gradient($white 95%, $light-grey 95%, $light-grey 100%);

  @media (max-width: $breakpoint-md) {
    margin-bottom: 0;
  }

  .featuresWrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    position: relative;
    margin: 0 14.167vw;
    margin-top: -30px;
    width: 100%;
    text-align: center;
    background: $white;
    border-radius: 2.222vw;
    box-shadow: 0px 48px 48px 0px $box_shadow;
    @media (max-width: $breakpoint-md) {
      transform: translate(0, 0);
      order: -1;
      position: relative;
      margin: 25px 0 94px 16px;
      height: auto;
      width: calc(100% - 16px);
      overflow-x: scroll;
      border-radius: 24px 0 0 24px;
      scrollbar-width: none;
      -ms-overflow-style: none;
    }
    .featuresContainer {
      display: flex;
      flex-flow: column;
      position: relative;
      padding: 1.667vw;
      @media (max-width: $breakpoint-md) {
        padding: 16px;
      }
      .featuresContent {
        display: flex;
        flex-flow: row;
        padding-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          padding-bottom: 8px;
        }
        .featureIcon {
          margin-right: 1.111vw;
          @media (max-width: $breakpoint-md) {
            margin-right: 8px;
          }
        }
        .featuresTitle {
          text-align: left;
          p:first-child {
            font-family: 'TWKLausanne-450';
            font-size: 0.833vw;
            line-height: 1vw;
            padding-bottom: 0.556vw;
            color: $dark_grey;
            @media (max-width: $breakpoint-md) {
              font-size: 12px;
              line-height: 14.4px;
              padding-bottom: 8px;
            }
          }
          p:nth-child(2) {
            font-family: 'TWKLausanne-700';
            font-size: 1.25vw;
            line-height: 1.875vw;
            color: $violet_02;
            @media (max-width: $breakpoint-md) {
              font-size: 18px;
              line-height: 27px;
            }
          }
        }
      }
      .featuresDescription {
        width: 19.444vw;
        text-align: left;
        font-family: 'TWKLausanne-300';
        font-size: 0.972vw;
        line-height: 1.458vw;
        color: $medium_grey;
        padding-bottom: 0;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
          min-width: 221px;
        }
        a {
          position: absolute;
          bottom: 1.528vw;
          right: 1.806vw;
          img {
            width: 1.667vw;
            height: 1.111vw;
            @media (max-width: $breakpoint-md) {
              width: 24px;
              height: 16px;
            }
          }
        }
      }
    }
  }
  .featuresWrapper::-webkit-scrollbar {
    display: none;
  }

  .featuresHide {
    display: none;
  }
}