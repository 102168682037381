@import '../breakpoints.scss';
@import '../variables.scss';

.section {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  padding: 3.333vw 10.278vw;
  opacity: 0;
  transition: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    flex-flow: column-reverse nowrap;
    padding: 34px 16px;
    gap: 0;
  }
  &.visible {
    opacity: 1;
    .content {
      transform: translateY(0);
    }
    .image_container {
      transform: scale(1);
    }
  }
  &.bg_orange {
    background: $fermata_red;
    background: $static_Two_column_gradient;
    .content {
      color: $white;
    }
  }
  &.bg_white {
    background: $white;
    .content {
      color: $dark_grey;
    }
    .whiteBtn {
      background: $static_Two_column_gradient;
    }
  }
  &.bg_gray {
    background: $base-light-grey;
    .content {
      color: $dark_grey;
    }
    .whiteBtn {
      background: $static_Two_column_gradient;
    }
  }
  &.bg_black {
    background: $dark_grey;
    .content {
      color: $white;
    }
  }
  &.text_right {
    flex-flow: row-reverse nowrap;
    @media (max-width: $breakpoint-md) {
      flex-flow: column-reverse nowrap;
    }
    .content {
      margin-left: 45px;
    }

  }
  &.darkTheme {
    .content {
      color: $dark_grey;
    }
  }
  &.lightTheme {
    .content {
      color: $white;
    }
  }
  .content {
    flex: 1;
    max-width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      margin-top: 24px;
      max-width: 100vw;
      margin-right: 0;
    }
    .subtitle > p {
      font-family: 'TWKLausanne-450';
      text-transform: uppercase;
      font-size: 0.833vw;
      line-height: 1;
      margin-bottom: 1.5vw;
      color: $fermata_red;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
        margin-bottom: 8px;
      }
    }
    .title {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 24px;
      }
      h2 {
        font-family: 'TWKLausanne-300';
        font-size: 3.333vw;
        font-weight: 700;
        line-height: 4vw;
        @media (max-width: $breakpoint-md) {
          font-size: 32px;
          line-height: 38.4px;
          max-width: 100%;
        }
        strong {
          font-family: 'TWKLausanne-700';
        }
      }
    }
    .description {
      font-family: 'TWKLausanne-300';
      margin: 1.5vw 0;
      width: 90%;
      @media (max-width: $breakpoint-md) {
        margin-bottom: 24px;
        width: 100%;
      }
      p {
        font-size: 0.97vw;
        line-height: 1.5;
        @media (max-width: $breakpoint-md) {
          font-size: 14px;
          line-height: 21px;
        }
      }

      a {
        display: inline !important
      }
    }
  }

  .video_wrapper{
    flex: 1;
    transform-origin: center center;
    transition: transform 0.8s ease;

    @media (max-width: $breakpoint-md) {
      max-width: 100%;
      width: 100%;
    }

    .videoContainer {
      padding: 56.25% 0 0 0;
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 2.222vw 0 2.222vw 2.222vw;
      overflow: hidden;

      @media (max-width: $breakpoint-xl) {
        padding: 0;
        min-height: 26vh;
        overflow: hidden;
      }
      @media (max-width: $breakpoint-md) {
        min-height: 40vh;
        overflow: hidden;
      }

      .videoFrame {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;

        @media (max-width: $breakpoint-xxl) {
          transform: scale(1.2);
        }

        @media (max-width: $breakpoint-xl) {
          transform: scale(2);
        }
      }

      .videoPlaceholder {
        @extend .videoFrame;
      }
    }
  }

  .image_container {
    max-width: 35.889vw;
    flex: 1;
    transform-origin: center center;
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
    }
    img {
      vertical-align: middle;
      width: 100%;
      height: 100%;
      border-radius: 2.222vw 0 2.222vw 2.222vw;
      object-fit: cover;
    }
  }
  .btn span {
    display: inline-block;
    vertical-align: middle;
  }
  a {
    display: flex;
    align-items: center;
  }
  .btn::after {
    background-image: url('/mixed-arrow.svg');
    content: ' ';
    width: 1.9vw;
    height: 1.111vw;
    background-size: cover;
    margin-left: 0.833vw;
    display: inline-block;
    vertical-align: middle;
    @media (max-width: $breakpoint-md) {
      width: 22.75px;
      height: 14px;
      margin-left: 12px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  .secondary_white:hover::after {
    background-image: url('/mixed-arrow-red.svg');
  }

  .secondary_white:active::after {
    background-image: url('/mixed-arrow-active.svg');
    background-size: contain;
    background-repeat: no-repeat;
    height: 0.972vw;
  }
}
