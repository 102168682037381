@import '../variables.scss';

.fourColumnStepsWrapper {
  position: relative;
  padding: 8.333vw 14.167vw;
  opacity: 0;
  transform: opacity 0.8s ease;
  @media (max-width: $breakpoint-md) {
    padding: 64px 16px;
  }
  &.visible {
    opacity: 1;
    .textWrapper {
      transform: translateY(0);
    }
    .cardWrapper .card {
      transform: scale(1);
    }
  }
  .textWrapper {
    width: 59.444vw;
    margin: 0 auto 3.333vw auto;
    text-align: center;
    transform: translateY(20px);
    transition: transform 0.8s ease;
    @media (max-width: $breakpoint-md) {
      width: 100%;
      margin: 0 0 48px 0;
    }
    .subtitle > p {
      font-size: 0.833vw;
      text-transform: uppercase;
      line-height: 1vw;
      font-family: 'TWKLausanne-450';
      color: $fermata_red;
      margin-bottom: 1.111vw;
      @media (max-width: $breakpoint-md) {
        font-size: 12px;
        line-height: 14.4px;
        margin-bottom: 16px;
      }
    }
    .title > p {
      font-family: 'TWKLausanne-700';
      font-size: 3.333vw;
      line-height: 4vw;
      color: $dark_grey;
      margin-bottom: 2.222vw;
      @media (max-width: $breakpoint-md) {
        font-size: 32px;
        line-height: 38.4px;
        margin-bottom: 32px;
      }
    }
    .description {
      font-size: 1.25vw;
      color: $medium_grey;
      line-height: 1.875vw;
      @media (max-width: $breakpoint-md) {
        font-size: 18px;
        line-height: 27px;
      }
    }
  }
  .cardWrapper {
    display: flex;
    gap: 1.667vw;
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      gap: 16px;
    }
    .card {
      position: relative;
      width: 25%;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        width: 100%;
      }
      img {
        border-radius: 0.833vw 0.833vw 0.833vw 0;
        @media (max-width: $breakpoint-md) {
          border-radius: 12px 12px 12px 0;
        }
      }
      .category {
        position: absolute;
        top: 1.111vw;
        left: 1.111vw;
        background: $light_grey;
        box-shadow: 0px 3.333vw 3.333vw $box_shadow;
        border-radius: 6.944vw;
        text-transform: uppercase;
        padding: 0.278vw 1.111vw;
        color: $discharging;
        font-size: 0.833vw;
        line-height: 120%;
        font-family: 'TWKLausanne-700';
        z-index: 2;
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          border-radius: 100px;
          padding: 4px 16px;
          top: 16px;
          left: 16px;
          box-shadow: 0px 48px 48px $box_shadow;
        }
      }
      .cardDescription {
        background: $white;
        padding: 1.111vw;
        box-shadow: 0px 3.333vw 3.333vw $box_shadow;
        border-radius: 0.833vw;
        margin: -2.083vw 1.111vw 0 1.111vw;
        position: relative;
        @media (max-width: $breakpoint-md) {
          margin: -32px 16px 0 16px;
          padding: 16px;
          border-radius: 12px;
          box-shadow: 0px 48px 48px $box_shadow;
        }
        .cardTitle > p {
          font-family: 'TWKLausanne-700';
          font-size: 1.25vw;
          line-height: 1.875vw;
          color: $dark_grey;
          margin-bottom: 0.278vw;
          @media (max-width: $breakpoint-md) {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 4px;
          }
        }
        .cardText > p {
          font-family: 'TWKLausanne-300';
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: $medium_grey;
          margin-bottom: 1.111vw;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}
