@import '../variables.scss';

.searchResultsWrapper {
  position: relative;
  &.visible {
    .searchResultsHero {
      opacity: 1;
    }
    .searchResultsText {
      transform: translateY(0);
    }
    .resultsWrapper .result {
      transform: scale(1);
    }
  }
  .searchResultsHero {
    background: $light_grey;
    display: flex;
    flex-direction: column;
    padding: 8.333vw 0 4.444vw 14.167vw;
    opacity: 0;
    transition: opacity 0.8s ease;
    @media (max-width: $breakpoint-md) {
      padding: 64px 16px 205px 16px;
    }
    .searchResultsText {
      transform: translateY(20px);
      transition: transform 0.8s ease;
      .subtitle {
        font-family: 'TWKLausanne-450';
        font-size: 0.833vw;
        line-height: 1vw;
        color: $fermata_red;
        text-transform: uppercase;
        margin-bottom: 1.111vw;
        @media (max-width: $breakpoint-md) {
          font-size: 12px;
          line-height: 14.4px;
          margin-bottom: 8px;
        }
      }
      .title {
        font-family: 'TWKLausanne-700';
        font-size: 4.444vw;
        line-height: 4.444vw;
        color: $dark_grey;
        margin-bottom: 2.222vw;
        @media (max-width: $breakpoint-md) {
          font-size: 48px;
          line-height: 57.6px;
          margin-bottom: 24px;
        }
      }
      .text {
        font-family: 'TWKLausanne-300';
        font-size: 1.25vw;
        line-height: 1.875vw;
        color: $medium_grey;
        @media (max-width: $breakpoint-md) {
          font-size: 18px;
          line-height: 27px;
        }
      }
    }
  }
  .resultsWrapper {
    padding: 4.444vw 14.167vw;
    @media (max-width: $breakpoint-md) {
      padding: 64px 0px;
    }
    .result {
      position: relative;
      transform: scale(0.8);
      transform-origin: center center;
      transition: transform 0.8s ease;
      @media (max-width: $breakpoint-md) {
        display: block;
      }
      .resultTextWrapper {
        padding: 2.222vw 4.444vw 2.222vw 6.111vw;
        position: relative;
        @media (max-width: $breakpoint-md) {
          padding: 24px 40px;
        }
        &:after {
          content: '';
          display: block;
          background-image: url('/arrow-right.svg');
          background-size: cover;
          width: 1.667vw;
          height: 1.667vw;
          position: absolute;
          right: 4.444vw;
          top: 50%;
          transform: translateY(-50%);
          @media (max-width: $breakpoint-md) {
            display: none;
          }
        }
        .resultTitle {
          font-family: 'TWKLausanne-700';
          font-size: 1.25vw;
          line-height: 1.875vw;
          color: $fermata_violet;
          margin-bottom: 0.556vw;
          max-width: 53.333vw;
          @media (max-width: $breakpoint-md) {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: 16px;
            max-width: 75%;
            position: relative;
            &:after {
              content: '';
              background-image: url('/arrow-right.svg');
              width: 24px;
              height: 24px;
              position: absolute;
              display: block;
              right: -24px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
        .resultDescription {
          font-family: 'TWKLausanne-300';
          font-size: 0.972vw;
          line-height: 1.458vw;
          color: $medium_grey;
          max-width: 53.333vw;
          @media (max-width: $breakpoint-md) {
            font-size: 14px;
            line-height: 21px;
            max-width: 100%;
          }
        }
      }
      .btnWrapper {
        padding-right: 6.111vw;
        .btn {
          background: none;
          border: none;
          padding: 0;
        }
      }
    }
  }
}

.result::after {
  position: absolute;
  bottom: 0;
  content: ' ';
  width: 100%;
  height: 0.139vw;
  background: $light_grey;
  border-radius: 32px;
  @media (max-width: $breakpoint-md) {
    height: 2px;
  }
}
